.addcustomercomponent{
    background-color: #FBFCFF;
    .selectCC {
        width: 100px !important;
    }
    .numberInputCC {
        margin-right: 75px !important;
    }
    .flexRowColumn{
        display: flex;
        flex-direction: row;
    }
    .width50100 {
        width: 50%;
    }
    .radioButton {
        margin: 0 5%;
    }
    .mt2 {
        margin-top: 10px !important;
    }
    .width92100 {
        width: 92% !important;
    }
.cardDiv {
    margin: 0 5% 1% 10%;
    padding-bottom: 2rem;
    .previewComponent{
        padding-left: 2rem;
        padding-right: 2rem;
    }

.imgPreview{
  text-align: center;
//   margin: auto;
  margin-top:5%;
  height: 200px;
  width: 220px;
  border: 1px dashed #4D66F0;
  border-radius:4px;
  position: relative;
  .customerprofiile{
        width: 220px;
        height: 200px;
        border-radius:2px;
       
      }
    .previewText{
        margin-top: 20px;
        .placetext{
            margin-top: 20px;
            color: #8E8E98;
            font-size: 14px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
  .fileInput{
    cursor: pointer;
    border-radius:4px;
    height: 200px;
    width: 220px;
    opacity:0;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
label{
    color:#8E8E98;
    font-size:14px
}

.imgPreviewcheck{
    text-align: center;
  //   margin: auto;
    margin-top:3%;
    height: 200px;
    width: 376px;
    border: 1px dashed #4D66F0;
    border-radius:4px;
    position: relative;
    .check{
          width: 376px;
          height: 200px;
          border-radius:2px;
        }
        .previewText{
            margin-top: 20px;
            .placetext{
                margin-top: 20px;
                color: #8E8E98;
                font-size: 14px;
            }
        }
    .fileInputcheck{
      cursor: pointer;
      border-radius:4px;
      height: 200px;
      width: 376px;
      opacity:0;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
  .docuploadAddCust{
    border: 1px solid lightgrey;
    border-radius:4px;
    height: 41px;
    width: 70%;
  .imgPreviewdoc{
    text-align: center;
  //   margin: auto;
    // margin-top:5%;
    height: 41px;
    width: 100%;
    // border: 1px dashed #4D66F0;
    border-radius:4px;
    position: relative;
    .doc{
          width: 350px;
          height: 41px;
          border-radius:2px;
        }
        .previewText{
            margin-top: 20px;
            .placetext{
                margin-top: 20px;
                color: #8E8E98;
                font-size: 14px;
            }
        }
    .fileInputdoc{
      cursor: pointer;
      border-radius:4px;
      height: 41px;
      opacity:0;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
//   input[type="file" i]{
//       opacity: 0;
//   }
}
.eyeline {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    width:20px;
}
.cloud {
    position: absolute;
    top: 12px;
    left: 10px;
    cursor: pointer;
    width:20px;
}
.newfield{
    position: absolute;
    right: 10%;
    margin: 10px 0;
    cursor: pointer;
}

.MuiInput-root {
    position: relative;
    // border: 1px solid #D4D4D5!important;
    width: 85%;
    border-radius: 5px;
    height:41px;
}


.InputLabel{
    width: 74%;
    margin-bottom: 10px;
    font-weight: 400;
    margin-top:20px;
    color: #8E8E98;
}
.cameradefault {
    position: absolute;
    left: 53%;
    bottom: 11%;
}
.categorytextFieldStyle {
    // #standard-select {
        .MuiInput-root{
            width:100%;         
        // }
    }
    .MuiSelect-select{
        text-align: left;
    }
    .MuiSelect-select:focus{
        background-color: transparent;
    }
}
.incomefield{
    width: 84%;
    border: 1px solid lightgrey;
    border-radius: 5px;
    height: 41px;
    .MuiInput-root{
        width: 100%;
    }
    .MuiInput-root:focus{
        width: 100%;
        background-color: transparent;
    }
}
.incomefield:focus{
    background-color: transparent;
    width: 84%;
    border: 1px solid lightgrey;
    border-radius: 5px;
}

.RadioDiv{
    padding-left: 5rem;
    .round {
        position: relative;
      }
      .round label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 17px;
        margin-top: 5px;
        left: 0;
        position: absolute;
        top: 0;
        width: 17px;
      }
      .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 2px;
        opacity: 0;
        position: absolute;
        top: 2px;
        transform: rotate(-45deg);
        width: 10px;
      }
      .round input[type="checkbox"] {
        visibility: hidden;
      }
      .round input[type="checkbox"]:checked + label {
        background-color: #66bb6a;
        border-color: #66bb6a;
      }
      .round input[type="checkbox"]:checked + label:after {
        opacity: 1;
      }
}
input[type='radio'] {
    -webkit-appearance:none;
    width:20px;
    height:20px;
    border:1px solid lightgray;
    border-radius:50%;
    outline:none;
    box-shadow:none;
    padding: 1px;
    // -ms-transform: scale(1.5); /* IE 9 */
    // -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    // transform: scale(1.5); 
    cursor: pointer;
}
input[type='radio']:hover {
    border:1px solid #00D95E;
}
input[type='radio']:focus {
    border:1px solid #00D95E;
}
input[type='radio']:before {
    content:'';
    display:block;
    width:50%;
    height:50%;
    margin: 20% auto;    
    border-radius:50%;    
}
input[type='radio']:checked:before {
    background:#00D95E;
    border:1px solid #00D95E;
    box-shadow:none;

}
.radio-item{
    display: flex
}
.MuiFormControl-marginNormal{
    width:84%;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-top: 0px;
    .MuiInput-root{
        width: 100%;
        height: 41px;
    }
}

}
.topName{
    margin-left: 10.2rem;
    color: #3E4664;
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 600;
}
.req{
    color: red;
}
.savebutton {
    background-color: #00D95E;
    text-transform: capitalize;
    color: #fff;
    height: 33px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 2px;
    position: relative;
    margin-right: 6.5rem;
}
    .savebutton:hover {
        background-color: #00D95E;
        text-transform: capitalize;
        color: #fff;
        height: 33px;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 2px;
        position: relative;
        margin-right: 6.5rem;}
.cancelbutton {
    border: 1px solid #E4E4E4;
    text-transform: capitalize;
    color: #8E8E98;
    height: 33px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 2px;
    margin: 2%;
    position: relative;
}
.react-date-picker__calendar{
//     // width: 350px !important;
//     // max-width: 100vw !important;
//     position: absolute;
//     top: 100% !important;
//     // left: 0 !important;
//     // z-index: 1 !important;
// margin-top: 200px;
margin-bottom: -342px;
}
// input[type="checkbox"] {
//     border-radius: 50%;
//     background: transparent;
//     width: 1.25em; height: 1.25em;
//     margin: 5px 1px 0px 0px;
// }


}

@media only screen and (min-device-width: 0px) and (max-device-width: 959px) {
    .addcustomercomponent {
        .flexRowColumn{
            display: flex;
            flex-direction: column;
        }
        .width50100 {
            width: 100%;
        }
        .MuiInput-root, .incomefield, .MuiFormControl-marginNormal {
            width: 100% !important;
        }
        .mt2 {
            margin-top: 5% !important;
        }
        .width92100 {
            width: 100% !important;
        }
    }
}

@media only screen and (min-device-width: 500px) and (max-device-width: 959px) {
    .addcustomercomponent {
        .cardDiv {
            margin: 0 3% 1% 12% !important;
            .previewComponent{
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
        .selectCC {
            width: 180px !important;
        }
        .numberInputCC {
            margin-right: 0px !important;
        }
    }
}

@media only screen and (min-device-width: 0px) and (max-device-width: 499px) {
    .addcustomercomponent {
        .cardDiv {
            margin: 0 2% 1% 21% !important;
            .previewComponent{
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
        .selectCC {
            width: 120px !important;
        }
        .numberInputCC {
            margin-right: 0px !important;
        }
    }
}
