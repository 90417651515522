.loancomponent{
    .card2{  
        display:block;
        margin: 47px;
        float: left;
        width: '4vw';
        height: '45vw';
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        color: #fff;
        border-radius: 5px;
        font-size: 150%;
        width: 221px;
        margin: 3rem;
     
    }
    .Applogo{
        width:20%;
        height:20%;
        margin-left: 4.5rem;
    }
    .cards{
    //   margin-top:2.5rem;
      height:auto;
      margin-left: 5rem;
    }
    .addButton .addLoan{
      
        background-color: #00D95E;
        color:white;
        position: absolute;
        right: 44px;
        height:2.5rem;
        width:10rem;
        text-align: center;
        // margin-left: 26rem;
        border-radius:5px;
        text-transform: capitalize;
        font-weight: 600;
    }
    .addButton:hover .addLoan{
        color:white;
        background-color:#00D95E;
    }
    .addButton{
        margin-top:1rem;  
    }
    .loans{
        font-size: 17px;
        text-align: center;
        color: #3E4664;
    }
    .card2:hover .deleteIcon{
        color: white;
        float:right;
        display: list-item;
    }
    .card2:hover .loans{
        color: white;
    }
    .card2:hover .editIcon{
        color: white;
        float: right;
    }
    .card2:hover{
        background-image: linear-gradient(to bottom right, #00D95E ,#4277DB );
        color: #ffff;    
    }
    .deleteIcon,.editIcon{
        top:0;
        left:0;
        color: white;
        float:right;
    }
    .card2:hover{
        color:white;
    }
    .icons Button{
        display: block;
        max-width: 20px;
    }
    .iconButton{
        padding:0rem;
        max-width: 20px !important;
    }
    .deleteIcon{
        width:0.8rem !important;
        height:0.8rem !important;
        margin-top:1rem;
    }
    .editIcon{
        width:0.8rem !important;
        height:0.8rem !important;
    }
    .icons{
        position: absolute;
        top: 15%;
        left: 84%;  
        width:20px !important;
        margin-bottom:2rem;
    }
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (min-width: 320px) and (max-width: 480px) {
        .loancomponent{
            .card2{  
                float: none;
                margin:1rem;
                margin-bottom:2rem;
                
             
            }
            .addButton .addLoan{   
                right: 10%;
                height:2.5rem;
                width:9rem;
                }
           .card2:hover .editIcon{
                        float: right;
            }
            }     
      }
      /* Smartphones (landscape) ----------- */
      @media only screen and (min-device-width: 481px) and (max-device-width: 767px) and (min-width: 481px) and (max-width: 767px) {
         
      }
      /* Smartphones (portrait) ----------- */
      @media only screen and (max-width: 320px) {
        .loancomponent{
            .card2{  
                float: none;
                margin:1rem;
                margin-bottom:2rem;
                
             
            }
            .addButton .addLoan{   
                right: 10%;
                height:2.5rem;
                width:9rem;
                }
           .card2:hover .editIcon{
                        float: right;
            }
            }     
          }
      /* iPads (portrait and landscape) ----------- */
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
         
      }
      /* iPads (landscape) ----------- */
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        /* STYLES GO HERE */
      }
      /* iPads (portrait) ----------- */
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        /* STYLES GO HERE */
      }
      /* Desktops and laptops ----------- */
      @media only screen and (min-width: 1224px) {
        /* STYLES GO HERE */
      }
      /* Large screens ----------- */
      @media only screen and (min-width: 1824px) {
        /* STYLES GO HERE */
      }
      /* iPhone 5 (portrait &amp; landscape)----------- */
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) {
        /* STYLES GO HERE */
      }
      /* iPhone 5 (landscape)----------- */
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
        /* STYLES GO HERE */
      }
      /* iPhone 5 (portrait)----------- */
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
        /* STYLES GO HERE */
      }