.personInfodialogbox {
    .MuiDialog-paperWidthSm {
        // position: absolute!important;
        max-width: 1000px !important;
        left: 0% !important;
        top: 0% !important;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 2rem 3rem 2rem 3rem;
        height: 36rem !important;
    }
    .TitleDiv {
        // width: 100% !important;
        height: 2rem !important;
        padding: 0rem !important;
        right: 0rem;
        display: flex;
        .mainTitle {
            text-align: center;
            margin: 0rem;
            color: rgb(136, 124, 124);
        }
        .personcloseButtonDiv {
            cursor: pointer;
            position: absolute;
            right: 7px;
            top: 15px;
            bottom: 0;
            color: rgb(45, 45, 206);
        }
        .TitleDiv {
            width: 100% !important;
            height: 2rem !important;
            padding: 0rem !important;
            right: 0rem;
            .mainTitle {
                text-align: center;
                margin: 0rem;
                color: rgb(136, 124, 124);
            }
            .personcloseButtonDiv {
                cursor: pointer;
                position: absolute;
                right: 7px;
                top: 15px;
                bottom: 0;
                color: rgb(45, 45, 206);
            }
        }
    }
    .bottomPart1 {
        width: 90%;
    }
    .mainDiv {
        display: block;
        .heading {
            margin-top: 2.5rem;
            font-size: 19px;
            font-weight: 800;
            display: flex;
            justify-content: space-between;
            width: 90%;
            .editIcon {
                cursor: pointer;
            }
        }
        .CustomerInfo {
            margin-top: 1rem;
        }
        .CustomerContentInfo {
            margin-top: 5px;
            margin-right: 100px;
            // width: 100px;
        }
        .firstDiv {
            display: flex;
            margin-top: 2rem;
            .firstDivOne {
                display: block;
                width: 50%;
                .firstDivOneOne {
                    display: flex;
                    .firstDivOneOneOne {
                        width: 50%;
                        .personalImage {
                            height: 150px;
                            width: 150px;
                            .profileImage {
                                max-height: 100%;
                                max-width: 100%;
                            }
                        }
                        .firstDivOneOneTwo {
                            width: 50%;
                            display: block;
                            .firstDivOneOneTwoFour {
                                margin-top: 0.2rem;
                                display: flex;
                            }
                        }
                    }
                }
            }
            .firstDivTwo {
                width: 50%;
                margin-left: 4rem;
            }
        }
        .secondDiv {
            display: flex;
            margin-top: 0.2rem;
            .secondDivOne {
                display: block;
                .secondDivOneOne {
                    .CustomerContentInfo {
                        display: block;
                    }
                }
            }
        }
        .thirdDiv {
            display: flex;
            margin-top: 0.2rem;
            .thirdDivOne {
                width: 50%;
            }
            .thirdDivTwo {
                width: 50%;
                margin-left: 4rem;
            }
        }
        .fourthDiv {
            display: flex;
            margin-top: 0.2rem;
            .fourthDivOne {
                width: 50%;
            }
            .fourthDivTwo {
                width: 50%;
                margin-left: 4rem;
            }
        }
        .fifthDiv {
            display: flex;
            margin-top: 0.2rem;
            .fifthDivOne {
                width: 50%;
            }
            .fifthDivTwo {
                width: 50%;
                margin-left: 4rem;
            }
        }
        .sixthDiv {
            display: block;
            margin-top: 0.2rem;
            .sixthDivOne {
                display: flex;
                .CustomerContentInfo {
                    a:link {
                        text-decoration: none;
                    }
                    a:visited {
                        text-decoration: none;
                    }
                    a:hover {
                        text-decoration: underline;
                    }
                    a:active {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .MuiFormLabel-root {
        font-size: 14px !important;
    }
    .MuiFormLabel-root {
        font-size: 14px !important;
    }
     ::-webkit-scrollbar {
        width: 3px;
    }
     ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px white;
    }
     ::-webkit-scrollbar-thumb {
        background-color: rgb(99, 226, 15);
        height: 20px;
        outline: 1px solid white;
    }
}

// }