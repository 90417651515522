#root {
  height: 100%;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.css-2613qy-menu {
  font-family: "Lato" !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiInputBase-input {
  padding: 6px 0px 8px 11px !important;
  font-size: 16px!important;
  // font-weight: 300!important;
  // font: 300!important;
  font: message-box !important;
  // font:  !important;
  color: #3E4664 !important;
}
.MuiTooltip-tooltip {
  background-color: #3E4664 !important;
}
.MuiTooltip-tooltipPlacementTop {
position: relative;
}
.MuiSwitch-root {
  width: 47px!important;
  height: 34px!important;
}
.MuiSwitch-thumb {
  width: 15px!important;
    height: 15px!important;
}
.backLink:hover {
color:#00D95E;
text-decoration: underline;
cursor:pointer;
}
.help-block{
  font-size:11px;
  color:red;
  font-family: 'Lato'!important;
}
.help-block-user{
  font-size:11px;
  color:red;
  font-family: 'Lato'!important;
  text-align:left;
  padding-left:25rem
}
.MuiMenu-paper {
  // max-height: calc(100% - 96px);
  // margin-top: 3.6rem !important;
  -webkit-overflow-scrolling: touch;
}
.MuiSelect-select:focus{
  background-color:transparent !important;
}
.MuiSlider-thumb {
  width: 13px !important;
  height: 16px !important;
  display: flex;
  outline: 0;
  position: absolute;
  box-sizing: border-box;
  margin-top: -5px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  margin-left: -6px;
  border-radius: 15% !important;
  justify-content: center;
  background-color: currentColor;
  color:blue !important;
}
.MuiSlider-track {
  height: 7px !important;
  display: block;
  position: absolute;
  border-radius: 1px;
  background-color: currentColor;
  color:green !important
}
.MuiSlider-rail {
  width: 100%;
  height: 7px !important;
  display: block;
  opacity: 0.38;
  position: absolute;
  border-radius: 1px;
  background-color: currentColor;
}
.DateRangePicker__CalendarSelection{
  // 8A2BE2
  background-color: #D8BFD8 !important;
  border: none !important;
}
.DateRangePicker__MonthHeaderSelect{
  background-color: #3e4664 !important ;
  color: #ffff !important;
  // border: 1px solid #3e4664 !important;
  border-radius: 5px !important;
}
.DateRangePicker__MonthHeader{
  color: #3e4664 !important;
}
.DateRangePicker__WeekdayHeading abbr[title]{
  color: #3e4664 !important;

}
.DateRangePicker__Date--is-selected {
  color: black !important;
}
.MuiRadio-colorSecondary.Mui-checked:hover {
  background-color: transparent !important;
}
.MuiIconButton-colorSecondary:hover {
  background-color: transparent !important;
}
.MuiIconButton-root:hover {
  background-color: transparent !important;
}
::placeholder {
  color: #BEBEBF;
  font-size: 16px;
  // opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:#BEBEBF;
 font-size: 16px;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color:#BEBEBF;
 font-size: 16px;
}

// Custom styles for input
.css-1dlyelz-MuiButtonBase-root-MuiToggleButton-root{
  text-transform: initial !important;
  // padding: 2px 6px !important;
}

.css-1pwwtm0-MuiButtonBase-root-MuiButton-root {
  text-transform: initial !important;
}

.paymentElm {
  border: 1px solid gray !important;
  padding: 7px !important;
  border-radius: 5px !important;
}
