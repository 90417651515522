.otherincomepage {
    .GreenBTN {
        max-width: 300px;
        width: 100%;
        text-transform: uppercase;
        background-color: #00D95E !important;
        color: white;
        padding: 10px 30px;
        height: 34px;
    }
    .css-1w1rijm-MuiButtonBase-root-MuiButton-root.Mui-disabled{
        background-color: #b0b0b0 !important;
        color: #000;
    }
    .muiCustomButton {
        min-width: 64px;
        border: 1px solid rgba(0, 115, 230, 0.5);
        color: #0073e6;
        width: 100%;
        max-width: 300px;
        box-shadow: #ebf5ff 0 2px 0.5px inset, rgba(204, 229, 255, 0.2) 0 -3px 0 inset,
          rgba(204, 229, 255, 0.3) 0 1px 2px 0;
        padding: 5px 10px;
        border-radius: 5px;
    }
    
    .tableTitle {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        .titleDiv {
            display: flex;
            .title {
                margin: 0;
                padding-right: 25px;
               
            }
            .titleText{
                color: #a8a8a8;
            }
        }
        .collectionExportDiv {
            display: flex;
            position: relative;
            top: 21.8%;
            // right: 3%;
            color: #3e4664;
            .pdficon {
                cursor: pointer;
            }
            .excelicon {
                cursor: pointer;
            }
        }
    }
    .tableLaylout {
        .table {
            .tablHeadRowPayment {
                color: #b2b2b2;
                font-weight: bold !important;
                text-transform: capitalize;
                .tablHeadCellPayment {
                    background-color: #e1f0fa;
                    // position: sticky;
                    top: 0;
                    z-index: 1;                  
                    color: #a8a8a8;
                    padding: 8px 8px;
                }
            }
            .tableRow {
                // box-shadow: 0px 1px 2px 1px lightgrey;
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0);
                .CollectionAmtCol {
                    display: flex;
                    justify-content: space-around;
                    position: relative;
                    z-index: 1 !important;
                }
                .Paid {
                    color: #00d95e;
                }
                .Unpaid {
                    color: #c10707;
                }
                .NextDue {
                    color: #4d66f0;
                }
                .tooltip {
                    visibility: hidden;
                    min-width: 120px;
                    background-color: black;
                    color: #fff;
                    text-align: center;
                    padding: 5px 0;
                    border-radius: 6px;
                    position: absolute;
                    right: 50%;
                    z-index: 1px;
                }
                .CollectionAmtCol:hover {
                    .tooltip {
                        visibility: visible;
                    }
                }
            }
            .tableBody {
                max-height: 335px;
                overflow: auto;
            }
        }
        .MuiTableCell-root{
            padding: 14px 36px 14px 9px;
        }
    }
}