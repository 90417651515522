.ReportTablepage {
    margin-top: -18px;
    .mainBodyDiv {
        margin-left: 8.5%;
        margin-right: 5%;
        .secondDiv {
            display: flex;
            margin-top: 30px;
            .collectionMembercountDiv {
                position: relative;
                top: 22.5%;
                // left: 5%;
                color: #3e4664;
            }
            .Indication{
                display: flex;
                left: 49rem;
                top:8px;
                position: relative;
                .PaidIndication{
                    border-radius: 50%;
                    background-color: #0016d9;
                    width: 8px;
                    height: 8px;
                    margin-top: 4px;
                    margin-right: 7px;
                }
                .PaidName{
                    margin-right: 23px;
                    font-size: 12px;

                }
                .NotPaidIndication{
                    border-radius: 50%;
                    background-color: red;
                    width: 8px;
                    height: 8px;
                    margin-top: 4px;
                    margin-right: 7px;
                }
                .NotPaidName{
                    margin-right: 23px;
                    font-size: 12px;
                }
                .PartialPaidIndication{
                    border-radius: 50%;
                    background-color: #d900d9;
                    width: 8px;
                    height: 8px;
                    margin-top: 4px;
                    margin-right: 7px;
                }
                .PartialPaidName{
                    font-size: 12px;
                }
            }
            .collectionExportDiv {
                display: flex;
                position: relative;
                top: 21.8%;
                left: 76.2%;
                // right: 3%;
                color: #3e4664;
                .pdficon {
                    cursor: pointer;
                    padding-bottom: 5px;
                }
                .excelicon {
                    cursor: pointer;
                    padding-bottom: 5px;
                }
            }
        }
        .collectionCardDiv {
            // margin: 3.6%;
            // margin-top: -1%;
            width: 318mm;
            min-height: 240px;
            // margin-left: auto;
            // margin-right: auto;
            .tableHeadRow {
                background-color: #e1f0fa;
                color: #b2b2b2;
                text-align: center;
                .tableCell {
                    color: black;
                }
                .header {
                    padding-left: 30px;
                }
            }
            .rowTextColor {
                margin-bottom: 30px;
                text-align: inherit;
                // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, -11.12);
                box-shadow: 1px 1px 5px lightgrey;
                .switchBtton {
                    color: #00d95e;
                }
                .paid {
                    color:  #0016d9 !important;
                }
                .notPaid {
                    color: red !important;
                }
                .PartialPaid {
                    color: #d900d9!important;
                }
                .tablePrimaryCol {
                    display: flex;
                    // padding: 13px 10px !important;
                    padding: 10px 22px 10px 22px !important;

                    .tableImgDiv {
                        height: 30px;
                        width: 30px;
                        .tableImg {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                        }
                    }
                    .tableCustomerName {
                        font-weight: bold;
                    }
                    .tableLoanId {
                        font-size: 10px;
                        margin-top: -4px;
                        color: #a8b099;
                    }
                }
            }
            .rowTextColor:hover {
                cursor: pointer;
                background-image: linear-gradient(to right, #00d95e, #4277db);
                .changetext {
                    color: #ffff !important;
                }
            }
        }
        .changetext {
            font-weight: 400;
            font-family: "Lato" !important;
            color: "#3E4664 !important";
        }
        .MuiTableCell-root {
            padding: 2px 22px 2px 22px !important;
            // padding: .5% 1% !important;
        }
        .MuiTableCell-alignRight {
            text-align: left;
        }
        .MuiTableCell-head {
            color: #b2b2b2 !important;
        }
        .MuiTableCell-body {
            color: #3e4664 !important;
        }
    }
    .info {
        margin: 0px;
        padding-top: 7px;
    }
}