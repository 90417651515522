.HelpMainDiv{
    margin-left: 5rem;
    .Helphead{
        text-align: center;
    }
    .SaveButton{
        background-color: #00D95E;
        border: none;
        padding: 6px 17px 6px 17px;
        border-radius: 4px;
        text-transform: capitalize;
        color: #fff;

    }
    .MuiSvgIcon-root{
        width:19px;
    }
    .MuiTypography-body1{
        font-size:14px
    }
}

