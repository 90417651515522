.customTooltip {
  color: red;
  background: #e3c848;
  padding: 5px 15px;
  border-radius: 5px;
  
  font-size: 12px;
  font-weight: bold;
  

  .amountLayout {
    font-size: 15px;
    display: block;
  }
}

.recharts-tooltip-wrapper .recharts-tooltip-wrapper-right .recharts-tooltip-wrapper-bottom{
    padding: 0 !important;
}
.recharts-legend-wrapper{
  right: -2px !important;
  top: -37px !important
}
.recharts-wrapper{
  height: 100% !important;
  width: 100% !important;


  }
  .recharts-surface{
    height: 100% !important;
    width: inherit;
  }
  .areaChart{
    width: 100% ;
  }

  @media only screen and (min-width: 320) {
    .recharts-legend-wrapper{
      top:-4px !important;
      margin-top: 10% !important;
      
    }
  }
  @media only screen and (min-width: 321) and (max-width: 480) {
    .recharts-legend-wrapper{
      top:-4px !important;
      margin-top: 10% !important;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 600px) {
    .recharts-legend-wrapper{
      top:-4px !important;
      margin-top: 10% !important;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 767px) {
    .recharts-legend-wrapper{
      top:-37px !important
    }
  }

  @media only screen and (min-device-width: 0px) and (max-device-width: 500px) {
    .recharts-legend-wrapper{
      top:-4px !important;
      margin-top: 20% !important;
      width: auto !important;
    }
  }