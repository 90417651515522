.Customerfilter{
    width: 100%;
    // margin-top: 15px;
    // background-color: #FFFFFF;
    // padding-top: 15px;
    .MuiButton-root:hover{
        background-color: transparent !important;
    }
    .bar {
        background-color: transparent!important;
        // color: black!important;
        box-shadow: none!important;
        .title{
            text-align: left;
            width: 19%;
            padding-left: 6rem;
            .Tabbutton {
                border: none;
                color: #757575;
                text-transform: capitalize;
            }
        }
        .OptionDiv{
            width: 60%;
            text-align: end;
            display: flex;
            padding-right: 2.7rem;
            justify-content: flex-end;
            .textBox{
                background-color: #EFF2FF;
                border-radius: 5px;
                padding-top: 3px;
            }
           
        }
      
           
            .dropdown-menu {
                position: absolute !important;
                will-change: transform !important;
                top: 0px !important;
                left: 0px !important;
                transform: translate3d(-44px, 34px, 0px) !important;
                background-color: #3E4664;
                .dropdown-item{
                    color: #fff;
                    font-weight: 400
                }
                .dropdown-item:hover {
                    background-color: #757575;
                }
            }
            .dropdown-toggle::after {
                display: none;
            }
            .ButtonDiv {
                // position: absolute;
                // right: 50px; 
                // top: 14px;
                padding-left: 20px;
                .adduserbutton {
                    text-transform: capitalize;
                    color: #ffff !important;
                    padding-left: 15px;
                    padding-right: 15px;
                    border-radius: 5px;
                    height: 34px;
                    padding-top: 4px;
                    background-color: #00D95E !important;
                    color: #fff !important;
                    .plusiconButton{
                        padding-right: 5px;
                    }
                }
                .adduserbutton:hover {
                    text-transform: capitalize;
                    color: #ffff;
                    padding-left: 15px;
                    padding-right: 15px;
                    border-radius: 5px;
                    height: 34px;
                    padding-top: 4px;
                    background-color: #00D95E !important;
                    .plusiconButton{
                        padding-right: 5px;
                    }
                }
            }
            .option:hover{
                padding: 20px;
                text-decoration: none;
                color: #4D66F0 !important;
            }
        }
        .imagediv {
            padding-left: 15px;
            padding-top: 8px;
        }
        .gridDiv{
            padding-left: 15px;
            padding-top: 8px;
        }
    
       .MuiInputBase-input{
                padding: 0px 24px 8px 11px !important;
                font-size: 15px !important;
                // color: #3E4664 !important;
                font-family: "Lato" !important;
            }
            .MuiSelect-select:focus {
                background-color: transparent;
                color: #00D95E !important
            }
            // .MuiSelect-select{
            //     color: #00D95E !important
            // }
            .cardtypeicon{
                cursor: pointer;
            }
            .gridtypeicon {
                cursor: pointer;
            }
            // .MuiButton-root{
            //     color: #fff !important;
            // }
            .filterDrop {
                background-color:transparent;
                border: 1px solid #E4E4E4;
                color: #3E4664;
                height: 36px;
                .filtericon{
                    padding-left: 15px;
                    padding-bottom: 3px 
                }
            }
            .filterDrop:focus{
                background-color: transparent;
                border: 1px solid #00D95E;
                color: #00D95E;
                box-shadow: none !important;
            }
            .timeDrop {
                background-color: #EFF2FF;
                border: none;
                color: #3E4664;
                height: 36px;
                text-transform: capitalize;
                border-radius: 5px;
                margin-right: 8px;
                text-transform: capitalize;
                // .calendertimeicon{
                //     padding-left: 15px;
                //     padding-bottom: 3px 
                // }
        
            .timeDrop:focus{
                background-color: #EFF2FF;
                border: 1px solid #00D95E;
                color: #00D95E;
                box-shadow: none !important;
            }
           
}
}