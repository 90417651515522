.forgetpasswordpagePage {
    .mainDiv {
      width: 100%;
      margin-top: 0rem;
    }
    .firstDiv {
      text-align: center;
      margin: 3rem;
      margin-bottom: 2rem;
    }
    .firstDivOne {
      text-align: left;
      padding: 0% 20%;
    }
    .firstH3 {
      font-family: "Comic Sans MS";
      font-size: 30px;
      font-weight: 400;
    }
    .firstH4 {
      margin-top: 0.5rem;
      color: rgb(184, 184, 187);
    }
    .secondDiv {
      display: flex !important;
      width: 100%;
    }
    .ImageDiv {
      width: 50%;
      text-align: end;
      padding-top: 1rem;
    }
    .CardDiv {
      width: 50%;
      padding-bottom: 3rem;
    }
    .CardDiv2 {
      height: 100%;
      // border-radius: 13px!important;
    }
    .CardDiv3 {
      width: 60%;
      border-radius: 13px !important;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
        0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.8) !important;
    }
    .CardDiv4 {
      margin: 2rem !important;
    }
    .CardDiv5 {
      text-align: center;
      font-size: 20px;
    }
    form {
      padding: 1rem 0rem 1rem 0rem;
    }
    .btnDiv {
      padding: 2rem 0rem 1rem 0rem;
    }
    .linkDiv {
      text-align: end;
    }
    .inputBoxContent {
      border-bottom: 1px solid #8e8e98 !important;
    }
    // .mainCard {
    //     height: 100%;
    //     border-radius: 13px;
    //     box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    //   transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    // }
    input {
      color: #8e8e98;
    }
    h3 {
      font-weight: 400px;
    }
    .btn {
      background-image: linear-gradient(to right, #00d95e, #4277db);
      border: none !important;
      height: 3rem !important;
      color: white !important;
      text-transform: none !important;
    }
    .loginIMage {
      height: 21rem !important;
      width: 100%;
    }
    // .MuiInputBase-input{
    //     padding:  6px 0px 8px 1px !important;
    //     font-size: 16px!important;
    //   font-weight: 300!important;
    //   color: #3E4664 !important;
    //   }
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (min-width: 320px) and (max-width: 480px) {
    .forgetpasswordpagePage {
      .firstDivOne {
        padding: 0% 5%;
      }
      .firstH4 {
        font-size: 13px;
      }
      .firstDiv {
        margin-bottom: 1rem;
      }
      .firstH3 {
        font-size: 18px;
        font-weight: 400;
      }
      .secondDiv {
        display: block !important;
       }
      .ImageDiv {
        width: 80%;
        padding: 0% 10%;
         }
      .CardDiv {
        width: 100%;
      }
      .CardDiv2 {
        padding: 0% 10%;
       
      }
      .CardDiv3 {
        width: 100%;
        }   
      .loginIMage {
        height: 15rem !important;
        width: 100%;
      }
     
    }
  }
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-device-width: 481px) and (max-device-width: 767px) and (min-width: 481px) and (max-width: 767px) {
      .forgetpasswordpagePage {
          .firstDivOne {
            padding: 0% 5%;
          }
          .firstH4 {
            font-size: 16px;
          }
          .firstDiv {
            margin-bottom: 1rem;
          }
          .firstH3 {
            font-size: 20px;
            font-weight: 400;
          }
          .secondDiv {
            display: block !important;
           }
          .ImageDiv {
            width: 70%;
            padding: 0% 15%;
             }
          .CardDiv {
            width: 100%;
          }
          .CardDiv2 {
            padding: 0% 10%;
           
          }
          .CardDiv3 {
            width: 100%;
            }   
          .loginIMage {
            height: 15rem !important;
            width: 100%;
          }
         
        }
  }
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width: 320px) {
      .forgetpasswordpagePage {
          .firstDivOne {
            padding: 0% 5%;
          }
          .firstH4 {
            font-size: 11px;
          }
          .firstDiv {
            margin-bottom: 1rem;
          }
          .firstH3 {
            font-size: 16px;
            font-weight: 400;
          }
          .secondDiv {
            display: block !important;
           }
          .ImageDiv {
            width: 80%;
            padding: 0% 10%;
             }
          .CardDiv {
            width: 100%;
          }
          .CardDiv2 {
            padding: 0% 10%;
           
          }
          .CardDiv3 {
            width: 100%;
            }   
          .loginIMage {
            height: 13rem !important;
            width: 100%;
          }
         
        }
  }
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
      .forgetpasswordpagePage {
          .firstDivOne {
              padding: 0% 5%;
            }  
      }
  }
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    /* STYLES GO HERE */
  }
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    /* STYLES GO HERE */
  }
  /* Desktops and laptops ----------- */
  @media only screen and (min-width: 1224px) {
    /* STYLES GO HERE */
  }
  /* Large screens ----------- */
  @media only screen and (min-width: 1824px) {
    /* STYLES GO HERE */
  }
  /* iPhone 5 (portrait &amp; landscape)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) {
    /* STYLES GO HERE */
  }
  /* iPhone 5 (landscape)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
    /* STYLES GO HERE */
  }
  /* iPhone 5 (portrait)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
    /* STYLES GO HERE */
  }