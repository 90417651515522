.LoanCustomerheader{
    display: block;
    .headerDiv{
        display: flex;
        .headerDivOne{
            margin-left: 51%;
        }
    }
    .bar{
        color: #fff;
    background-color: #fdfdff;
    }
    .searchDiv{
        position: absolute;
        width: 30%;
        right: 3%;
        text-align: left;
        display: flex;
        
        // margin-left: 30%;
        background-color: #EFF2FF;
        border-radius: 8px;
    }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (min-width: 320px) and (max-width: 480px) {
   
    .LoanCustomerheader{
        
        .headerDiv{
            display: block;
            
        }
        
        .searchDiv{
            position: relative;
            width: 90%;
       margin-left: 30%;
       margin-bottom: 10px;
        }
    }
    
  }
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-device-width: 481px) and (max-device-width: 767px) and (min-width: 481px) and (max-width: 767px) {
     
  }
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width: 320px) {
    .LoanCustomerheader{
        
        .headerDiv{
            display: block;
            
        }
        
        .searchDiv{
            position: relative;
            width: 70%;
       margin-left: 30%;
       margin-bottom: 10px;
            
        }
    }
      }
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
     
  }
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    /* STYLES GO HERE */
  }
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    /* STYLES GO HERE */
  }
  /* Desktops and laptops ----------- */
  @media only screen and (min-width: 1224px) {
    /* STYLES GO HERE */
  }
  /* Large screens ----------- */
  @media only screen and (min-width: 1824px) {
    /* STYLES GO HERE */
  }
  /* iPhone 5 (portrait &amp; landscape)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) {
    /* STYLES GO HERE */
  }
  /* iPhone 5 (landscape)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
    /* STYLES GO HERE */
  }
  /* iPhone 5 (portrait)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
    /* STYLES GO HERE */
  }
