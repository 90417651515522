.Deleteuserpage{
        .TitleuserDiv{
            // width: 307px;
            // height: 113px;
            .deleteuserconfirmation{
                text-align: center;
                .confrmusermessage{
                    margin-top: 20px;
                    margin-bottom: 5px;
                }
                .UserName{
                    color: #3E4664;
                    margin: 0px;
                    font-size: 17px!important; 
                }
            }
        }
        .bottomPart {
            text-align: end;
            .cancelbutton{
                border: 1px solid #E4E4E4;
                text-transform: capitalize;
                color: #8E8E98;
                height: 30px;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 2px;
                margin: 3%;
                // position: relative;
                // left: 50px;

            }
            .savebutton{
                background-color: #00D95E;
                text-transform: capitalize;
                color: #fff;
                height: 30px;
                padding-left: 25px;
                padding-right: 25px;
                padding-top: 2px;
                // position: relative;
                // left: 60px;
            }
        
    }
}
