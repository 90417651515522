.usermanagementpage{
    .MuiButton-root {
             line-height: 0 !important;      
    }
    .MuiTable-root {
        width: 100%;
        display: table;
        border-spacing: 0;
        border-collapse: collapse;
    }
    .MuiIconButton-root {
        flex: 0 0 auto;
        color: rgba(0, 0, 0, 0.54);
        padding: 12px;
        overflow: visible;
        font-size: 1.5rem;
        text-align: center;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 50%;
    }
        .mainBodyDiv{
            margin-left: 5.5%;
        .TopDiv {
            display: flex;
            position: relative;
        .searchDiv {
            background-color: #EFF2FF;
            width: 25%;
            height: 37px;
            border-radius: 10px;
            position: absolute;
            left: 50px; 
            top: 14px;
            // .iconButton {
            // }
            .input {
                position: absolute;
                top: 5px;    
            }
        }
        .ButtonDiv {
            position: absolute;
            right: 50px; 
            top: 14px;
            .adduserbutton {
                text-transform: capitalize;
                color: #ffff;
                padding-left: 15px;
                padding-right: 15px;
                border-radius: 10px;
                height: 37px;
                padding-top: 4px;
                .plusiconButton{
                    padding-right: 5px;
                }
            }
    }
    }
    .secondDiv {
        display: flex;
        .membercountDiv{
            // position:absolute;
            margin-top: 4.5%;
            margin-left: 4%;
            color: #3E4664;
        }
        .exportDiv {
            display: flex;
            position:absolute;
            right: 4%;
            margin-top: 4.5%;
            // margin-left: 77.5%;
            color: #3E4664;
            .pdficon{
                cursor: pointer;
            }
            .excelicon {
                cursor: pointer;
                margin-top: 19px;
            }
        }
    }
    .cardDiv {
        margin: 3.6%;
        margin-top: -1%;
        .Roww {
            box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0, -11.12);
            .switchBtton {
                color: #00D95E;
            }
        }
        .Roww:hover {
            background-image: linear-gradient(to right, #00D95E ,#4277DB );
            .changetext {
                color: #ffff !important;
            }
            
        }  
        .iconedit {
            cursor: pointer;
        }
        .icondelet {
            padding-left: 20px;
            cursor: pointer;
        }  
        
    }
    .changetext {
        font-weight: 400;
        font-family: 'Lato'!important;
        color:'#3E4664 !important'
    }
    .MuiTableCell-root{
        padding: .5% 1.8% !important;
        font-size: 0.875rem !important;
        
    }
    .MuiTableCell-alignRight {
        text-align: left;
    }
    .MuiTableCell-head {
        color: #B2B2B2 !important;
    }
    .MuiTableCell-body{
        color: #3E4664 !important;
        padding: 0px 22px 0px 22px !important;
    }
    }
    // .MuiSwitch-input{
    //     color: #E5FBEE;
    // }
    .imgButton {
        height: 32px;
       width: 32px;
       margin-top: 7px;
       border-radius: 50%;
       border:1px solid lightgray
       
           }
    }
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (min-width: 320px) and (max-width: 480px) {
    .usermanagementpage{       
        .MuiTable-root {
             overflow: scroll;
              }
        .MuiIconButton-root {
           padding: 6px ;
            }
            .mainBodyDiv{
               
            .TopDiv {
              
            .searchDiv {
                height: 30px;
                border-radius: 10px;
                left: 65px; 
                top: 14px;
                  .input {
                    top: 0px;    
                }
            }
            .ButtonDiv {
                right: 19px; 
                top: 14px;
                .adduserbutton {
                    padding-left: 7px;
                    padding-right: 7px;
                    border-radius: 10px;
                    height: 30px;
                    }
        }
        }
    
        .secondDiv {
            .membercountDiv{
                margin-left: 19%;
                 }
            .exportDiv {                
                .excelicon {                    
                    margin-top: 10px;
                }
            }
        }
    .tableCard{
        margin-left: 60px;
   }    
        .MuiTableCell-root{
            padding: .3% 1% !important;
            font-size: 0.179rem !important;
            
        }
        .MuiTableCell-alignRight {
            text-align: left;
        }
        }
        .imgButton {
             height: 15px;
            width: 15px;
            margin-top: 1px;
            border-radius: 50%;
             }
        }
    
}
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-device-width: 481px) and (max-device-width: 767px) and (min-width: 481px) and (max-width: 767px) {
    .usermanagementpage{       
        .MuiTable-root {
             overflow: scroll;
              }
        .MuiIconButton-root {
           padding: 6px ;
            }
            .mainBodyDiv{
               
            .TopDiv {
              
            .searchDiv {
                height: 30px;
                border-radius: 10px;
                left: 65px; 
                top: 14px;
                  .input {
                    top: 0px;    
                }
            }
            .ButtonDiv {
                right: 19px; 
                top: 14px;
                .adduserbutton {
                    padding-left: 10px;
                    padding-right: 10px;
                    border-radius: 10px;
                    height: 32px;
                    }
        }
        }
    
        .secondDiv {
            .membercountDiv{
                margin-left: 15%;
                 }
            .exportDiv {                
                .excelicon {                    
                    margin-top: 19px;
                }
            }
        }
    .tableCard{
        margin-left: 60px;
   }    
        .MuiTableCell-root{
            padding: .3% 1% !important;
            font-size: 0.5rem !important;
            
        }
        .MuiTableCell-alignRight {
            text-align: left;
        }
        }
        .imgButton {
             height: 15px;
            width: 15px;
            margin-top: 1px;
            border-radius: 50%;
             }
        }
    
}    
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width: 320px) {
   
    .usermanagementpage{       
        .MuiTable-root {
             overflow: scroll;
              }
        .MuiIconButton-root {
           padding: 6px ;
            }
            .mainBodyDiv{
               
            .TopDiv {
              
            .searchDiv {
                height: 25px;
                border-radius: 10px;
                left: 50px; 
                top: 12px;
                  .input {
                    top: 0px;    
                }
            }
            .ButtonDiv {
                right: 13px; 
                top: 12px;
                .adduserbutton {
                    padding-left: 8px;
                    padding-right: 8px;
                    border-radius: 10px;
                    height: 29px;
                    }
        }
        }
    
        .secondDiv {
            .membercountDiv{
                margin-left: 25%;
                 }
            .exportDiv {                
                .excelicon {                    
                    margin-top: 12px;
                }
            }
        }
    .tableCard{
        margin-left: 60px;
   }    
        .MuiTableCell-root{
            padding: .3% 1% !important;
            font-size: 0.2rem !important;
            
        }
        .MuiTableCell-alignRight {
            text-align: left;
        }
        }
        .imgButton {
             height: 15px;
            width: 15px;
            margin-top: 1px;
            border-radius: 50%;
             }
        }
    
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
    .usermanagementpage{       
        .MuiTable-root {
             overflow: scroll;
              }
        .MuiIconButton-root {
           padding: 6px ;
            }
            .mainBodyDiv{
               
            .TopDiv {
              
            .searchDiv {
                height: 30px;
                border-radius: 10px;
                left: 65px; 
                top: 14px;
                  .input {
                    top: 0px;    
                }
            }
            .ButtonDiv {
                right: 19px; 
                top: 14px;
                .adduserbutton {
                    padding-left: 14px;
                    padding-right: 14px;
                    border-radius: 10px;
                    height: 36px;
                    }
        }
        }
    
        .secondDiv {
            .membercountDiv{
                margin-left: 12%;
                 }
            .exportDiv {                
                .excelicon {                    
                    margin-top: 19px;
                }
            }
        }
    .tableCard{
        margin-left: 60px;
   }    
        .MuiTableCell-root{
            padding: .3% 1% !important;
            font-size: 0.7rem !important;
            
        }
        .MuiTableCell-alignRight {
            text-align: left;
        }
        }
        .imgButton {
             height: 15px;
            width: 15px;
            margin-top: 1px;
            border-radius: 50%;
             }
        }
    
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    /* STYLES GO HERE */
  }
  
  /* Desktops and laptops ----------- */
  @media only screen and (min-width: 1224px) {
    /* STYLES GO HERE */
  }
  
  /* Large screens ----------- */
  @media only screen and (min-width: 1824px) {
    /* STYLES GO HERE */
  }
  
  /* iPhone 5 (portrait &amp; landscape)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) {
    /* STYLES GO HERE */
  }
  
  /* iPhone 5 (landscape)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
    /* STYLES GO HERE */
  }
  
  /* iPhone 5 (portrait)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
    /* STYLES GO HERE */
  }