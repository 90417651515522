.Allcardviewpage {
  display: flex;
  margin-left: 6.3rem;
  margin-right: 1.2rem;
  .collectionCardDiv {
    margin: 1.1rem;
    width: 22%;
    height: 138px;
    border-radius: 10px;

    .avatar {
      border-radius: 50%;
      background-color: transparent;
      width: 60px;
    }

    .collectionMoneydetailsDiv {
      color: rgb(252, 34, 18) !important;
      font-size: 18px;
      font-weight: 900 !important;
    }
    .collectionNameDiv {
      font-size: 14px;
      font-weight: 400;
      color: #757575;
    }

    .MuiCardContent-root {
      padding: 0px;
    }
    .collectionMoneydetailsDiv {
      color: #757575 !important;
      font-size: 30px;
      font-weight: 900 !important;
    }
  }
  .MuiCardHeader-root{
    padding: 13px !important;
  }
  .MuiPaper-elevation1{
    box-shadow: 1px 1px 5px lightgrey;
  }
}
