.dashboard {
    margin-left: 5rem;
    padding: 10px 40px 0px 40px;
    min-height: 95vh !important;
   
    
    .progressCards {
        grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
        display: grid;
        // display: flex;
        height: 27% !important;
        justify-content: space-between;
        padding: 10px 0;
        .scColorYellow {
            background: #e3c848;
            box-shadow: 0px 4px 10px 0px #e3c848;
            margin: 8px;
        }
        .scColorGreen {
            background: #5ac796;
            box-shadow: 0px 4px 10px 0px #5ac796;
            margin: 8px;
        }
        .scColorPurple {
            background: #b57dff;
            box-shadow: 0px 4px 10px 0px #b57dff;
            margin: 8px;
        }
        .scColorPink {
            background: #ff6d91;
            box-shadow: 0px 4px 10px 0px #ff6d91;
            margin: 8px;
        }
        .scInactiveCard {
            opacity: 0.2;
        }
    }
    .graphLayout {
        height: 60% !important;
        width: 91%;
        padding: 20px 0;
        display: flex;
        position: absolute;
         // justify-content: space-between;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .filterLayout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.statusCard {
    cursor: pointer;
    color: white;
    border-radius: 10px;
    font-size: 14px;
    // height: 8rem;
    padding: 10px 10px 10px 17px;
    // width: 16rem;
    width: 87%;
    .scInnerLayout {
        .scHeader {
            padding-top: 10px;
        }
        .scBody {
            display: flex;
            // justify-content: space-between;
            padding-top: 10px;
            align-items: center;
            .circularBar {
                width: 60px;
                // margin: auto;
            }
            .scValues {
                padding: 20px 15px;
                align-items: center;
                .mainAmountVal {
                    font-weight: bold;
                    font-size: 30px;
                }
            }
        }
    }
}

.table {
    .tablHeadRow {
        background-color: #e1f0fa;
        color: #b2b2b2;
        font-weight: bold !important;
        text-transform: capitalize;
    }
    .tablePrimaryCol {
        display: flex;
        padding: 0 10px !important;
        .tableImgDiv {
            height: 30px;
            width: 30px;
            .tableImg {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
        }
        .tableCustomerName {
            font-weight: bold;
        }
        .tableLoanId {
            font-size: 10px;
            margin-top: -4px;
            color: #a8b099;
        }
    }
    .CollectionAmtCol {
        display: flex;
        justify-content: space-around;
    }
    .Paid {
        color: #00d95e;
    }
    .Unpaid {
        color: #c10707;
    }
    .NextDue {
        color: #4d66f0;
    }
}

.lineChart {
    width: 58%;
    background: purple;
    height: 100%;
    border-radius: 10px;
    
}

.piechartLayout {
    width: 31%;
    box-shadow: 0px 3px 20px 4px rgba(36, 38, 52, 0.2);
    height: 93%;
    border-radius: 10px;
    padding: 1% 1%;
    .piechart {
        height: 70% !important;
        // align-items: center;
        // display: flex;
        // justify-content: center;
    }
}

.tableLayout {
    border-collapse: separate;
    border-spacing: 0 3px;
    width: 100%;
    text-align: left;
    .tableHead {
        .headRow {
            background: #989494;
            height: 3rem;
            .header {
                display: table-cell;
                padding-left: 10px;
            }
        }
    }
    .tableBodyRow {
        border-collapse: collapse;
        box-shadow: 0px 1px 4px 1px rgba(162, 133, 138, 0.66);
        height: 3rem;
        .tablePrimaryCol {
            display: flex;
            padding: 0 10px !important;
            .tableImgDiv {
                height: 30px;
                width: 30px;
                .tableImg {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
            }
            .nameLayout {
                padding-left: 12px;
                .tableCustomerName {
                    font-weight: bold;
                }
                .tableLoanId {
                    font-size: 10px;
                    margin-top: -4px;
                    color: #a8b099;
                }
            }
        }
    }
}

.dropdownbuttonlayout {
    .dropdownButton {
        cursor: pointer;
        min-width: 100px;
        background: #eff2ff;
        border: none;
        border-radius: 5px;
        padding: 10px 25px 10px 20px;
        font-size: 14px;
        position: relative;
        align-items: center;
        color:blue;
        font-weight: 600;
        .dropdownIcon {
            position: absolute;
            top: 15px;
            right: 10px;
        }
    }
    .hoverMenu {
        display: none;
        cursor: pointer;
        border-radius: 5px;
        position: absolute;
        width: 210px;
        padding-inline-start: 0px;
        list-style-type: none;
        max-width: 10rem;
        background: #3e466f;
        color: #d4d4d5;
        margin-left: -60px;
        z-index: 1;
        .menuItem {
            padding: 10px;
            .floatRightIcon {
                float: right;
            }
        }
        .menuItem:hover {
            background: #6c7289;
            color: white;
        }
    }
}

.dropdownbuttonlayout:hover {
    .hoverMenu {
        display: block;
    }
}

.collectionAgentlayout {
    .collectionAgentButton {
        cursor: pointer;
        min-width: 155px;
        border: none;
        border-radius: 5px;
        padding: 10px;
        font-size: 15px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background: #FBFCFF;
        color: lightslategray;
        font-weight: 400;
        .userSolid{
            padding-right: 7px;
        }
        .greenArrow {
            padding-left: 5px;
        }
    }
    .hoverMenu {
        display: none;
        cursor: pointer;
        border-radius: 5px;
        position: absolute;
        width: 210px;
        padding-inline-start: 0px;
        list-style-type: none;
        max-width: 10rem;
        background: #3e466f;
        color: #d4d4d5;
        margin-left: -10px;
        z-index: 1;
        .menuItem {
            padding: 10px;
            .floatRightIcon {
                float: right;
            }
        }
        .menuItem:hover {
            background: #6c7289;
            color: white;
        }
    }
}

.collectionAgentlayout:hover {
    .hoverMenu {
        display: block;
    }
}

.lineChartLayout {
    box-shadow: 0px 4px 10px 0px lightgrey;
    width: 62%;
    border-radius: 10px;
    margin-right: 2%;
    height: 100% !important;
    .lineChartHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 16% !important;

    }
}

.pieHeader {
    height: 15% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pieHead {
        font-weight: bold;
        font-size: 13px;
        color: blue;
        padding-left: 23px;
    }
    .pieHeadDropdown {
        margin-right: 12px;
    }
}

.amountDetailLayout {
    display: flex;
    flex-direction: row;
}

.amountDetail {
    cursor: pointer;
    border-radius: 5px;
    padding: 10px 10px 10px 20px;
    bottom: 0;
    width: 100px;
    .adHead {
        font-weight: bold;
        font-size: 20px;
    }
    .adBody {
        font-size: 10px;
        font-weight: bold;
    }
}

.amountDetailActive {
    background: #dffaea !important;
}

.innerLayout {
    padding: 0 12px;
    height: 100% !important;

}

.bold {
    font-weight: bold;
}

.defaultCursor {
    cursor: default !important;
}
.recharts-legend-item-text {
    font-size: 13px !important;
    color: lightslategray;
    padding-left: 6px;
    padding-right: 12px;
    font-weight: 600;
}

@media only screen and (max-width: 320px) {
    .dashboard {
        .progressCards {
            .scColorYellow {
            }
            .scColorGreen {
            }
            .scColorPurple {
            }
            .scColorPink {
            }
            .scInactiveCard {
            }
        }
        .graphLayout {
            display: block;
        }
    }
    
    .header {
        .filterLayout {
        }
    }
    
    .statusCard {
        .scInnerLayout {
            .scHeader {
            }
            .scBody {
                .circularBar {
                }
                .scValues {
                    .mainAmountVal {
                    }
                }
            }
        }
    }
    
    .table {
        .tablHeadRow {
        }
        .tablePrimaryCol {
            .tableImgDiv {
                .tableImg {
                }
            }
            .tableCustomerName {
            }
            .tableLoanId {
            }
        }
        .CollectionAmtCol {
        }
        .Paid {
        }
        .Unpaid {
        }
        .NextDue {
        }
    }
    
    .lineChart { 
    }
    
    .piechartLayout {
        width: 74%;
        margin-top: 3%;
        padding: 1% 1%;
        .piechart {
        }
    }
    
    .tableLayout {
        .tableHead {
            .headRow {
                .header {
                }
            }
        }
        .tableBodyRow {
            .tablePrimaryCol {
                .tableImgDiv {
                    .tableImg {
                    }
                }
                .nameLayout {
                    .tableCustomerName {
                    }
                    .tableLoanId {
                    }
                }
            }
        }
    }
    
    .dropdownbuttonlayout {
        .dropdownButton {
            .dropdownIcon {
            }
        }
        .hoverMenu {
            .menuItem {
                .floatRightIcon {
                }
            }
            .menuItem:hover {
            }
        }
    }
    
    .dropdownbuttonlayout:hover {
        .hoverMenu {
        }
    }
    
    .collectionAgentlayout {
        .collectionAgentButton {
            .userSolid{
            }
            .greenArrow {
            }
        }
        .hoverMenu {
            .menuItem {
                .floatRightIcon {
                }
            }
            .menuItem:hover {
            }
        }
    }
    
    .collectionAgentlayout:hover {
        .hoverMenu {
        }
    }
    
    .lineChartLayout {
        width: 74%;
        .lineChartHeader {
            display: block;
            padding-top: 1%;
        }
    }
    
    .pieHeader {
        .pieHead {
        }
        .pieHeadDropdown {
        }
    }
    
    .amountDetailLayout {
    }
    
    .amountDetail {
        .adHead {
        }
        .adBody {
        }
    }
    
    .amountDetailActive {
    }
    
    .innerLayout {
    }
    
    .bold {
    }
    
    .defaultCursor {
    }
    .recharts-legend-item-text {
    }
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .dashboard {
        .progressCards {
            .scColorYellow {
            }
            .scColorGreen {
            }
            .scColorPurple {
            }
            .scColorPink {
            }
            .scInactiveCard {
            }
        }
        .graphLayout {
            display: block;
        }
    }
    
    .header {
        .filterLayout {
        }
    }
    
    .statusCard {
        .scInnerLayout {
            .scHeader {
            }
            .scBody {
                .circularBar {
                }
                .scValues {
                    .mainAmountVal {
                    }
                }
            }
        }
    }
    
    .table {
        .tablHeadRow {
        }
        .tablePrimaryCol {
            .tableImgDiv {
                .tableImg {
                }
            }
            .tableCustomerName {
            }
            .tableLoanId {
            }
        }
        .CollectionAmtCol {
        }
        .Paid {
        }
        .Unpaid {
        }
        .NextDue {
        }
    }
    
    .lineChart { 
    }
    
    .piechartLayout {
        width: 74%;
        margin-top: 3%;
        padding: 1% 1%;
        .piechart {
        }
    }
    
    .tableLayout {
        .tableHead {
            .headRow {
                .header {
                }
            }
        }
        .tableBodyRow {
            .tablePrimaryCol {
                .tableImgDiv {
                    .tableImg {
                    }
                }
                .nameLayout {
                    .tableCustomerName {
                    }
                    .tableLoanId {
                    }
                }
            }
        }
    }
    
    .dropdownbuttonlayout {
        .dropdownButton {
            .dropdownIcon {
            }
        }
        .hoverMenu {
            .menuItem {
                .floatRightIcon {
                }
            }
            .menuItem:hover {
            }
        }
    }
    
    .dropdownbuttonlayout:hover {
        .hoverMenu {
        }
    }
    
    .collectionAgentlayout {
        .collectionAgentButton {
            .userSolid{
            }
            .greenArrow {
            }
        }
        .hoverMenu {
            .menuItem {
                .floatRightIcon {
                }
            }
            .menuItem:hover {
            }
        }
    }
    
    .collectionAgentlayout:hover {
        .hoverMenu {
        }
    }
    
    .lineChartLayout {
        width: 74%;
        .lineChartHeader {
            display: block;
            padding-top: 1%;
        }
    }
    
    .pieHeader {
        .pieHead {
        }
        .pieHeadDropdown {
        }
    }
    
    .amountDetailLayout {
    }
    
    .amountDetail {
        .adHead {
        }
        .adBody {
        }
    }
    
    .amountDetailActive {
    }
    
    .innerLayout {
    }
    
    .bold {
    }
    
    .defaultCursor {
    }
    .recharts-legend-item-text {
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 600px) {
    .dashboard {
        .progressCards {
            .scColorYellow {
            }
            .scColorGreen {
            }
            .scColorPurple {
            }
            .scColorPink {
            }
            .scInactiveCard {
            }
        }
        .graphLayout {
            display: block;
        }
    }
    
    .header {
        .filterLayout {
        }
    }
    
    .statusCard {
        .scInnerLayout {
            .scHeader {
            }
            .scBody {
                .circularBar {
                }
                .scValues {
                    .mainAmountVal {
                    }
                }
            }
        }
    }
    
    .table {
        .tablHeadRow {
        }
        .tablePrimaryCol {
            .tableImgDiv {
                .tableImg {
                }
            }
            .tableCustomerName {
            }
            .tableLoanId {
            }
        }
        .CollectionAmtCol {
        }
        .Paid {
        }
        .Unpaid {
        }
        .NextDue {
        }
    }
    
    .lineChart { 
    }
    
    .piechartLayout {
        width: 79%;
        margin-top: 3%;
        padding: 1% 1%;
        .piechart {
        }
    }
    
    .tableLayout {
        .tableHead {
            .headRow {
                .header {
                }
            }
        }
        .tableBodyRow {
            .tablePrimaryCol {
                .tableImgDiv {
                    .tableImg {
                    }
                }
                .nameLayout {
                    .tableCustomerName {
                    }
                    .tableLoanId {
                    }
                }
            }
        }
    }
    
    .dropdownbuttonlayout {
        .dropdownButton {
            .dropdownIcon {
            }
        }
        .hoverMenu {
            .menuItem {
                .floatRightIcon {
                }
            }
            .menuItem:hover {
            }
        }
    }
    
    .dropdownbuttonlayout:hover {
        .hoverMenu {
        }
    }
    
    .collectionAgentlayout {
        .collectionAgentButton {
            .userSolid{
            }
            .greenArrow {
            }
        }
        .hoverMenu {
            .menuItem {
                .floatRightIcon {
                }
            }
            .menuItem:hover {
            }
        }
    }
    
    .collectionAgentlayout:hover {
        .hoverMenu {
        }
    }
    
    .lineChartLayout {
        width: 79%;
        .lineChartHeader {
            display: block;
            padding-top: 1%;
        }
    }
    
    .pieHeader {
        .pieHead {
        }
        .pieHeadDropdown {
        }
    }
    
    .amountDetailLayout {
    }
    
    .amountDetail {
        .adHead {
        }
        .adBody {
        }
    }
    
    .amountDetailActive {
    }
    
    .innerLayout {
    }
    
    .bold {
    }
    
    .defaultCursor {
    }
    .recharts-legend-item-text {
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 767px) {
    .dashboard {
        .progressCards {
            .scColorYellow {
            }
            .scColorGreen {
            }
            .scColorPurple {
            }
            .scColorPink {
            }
            .scInactiveCard {
            }
        }
        .graphLayout {
            display: block;
        }
    }
    
    .header {
        .filterLayout {
        }
    }
    
    .statusCard {
        .scInnerLayout {
            .scHeader {
            }
            .scBody {
                .circularBar {
                }
                .scValues {
                    .mainAmountVal {
                    }
                }
            }
        }
    }
    
    .table {
        .tablHeadRow {
        }
        .tablePrimaryCol {
            .tableImgDiv {
                .tableImg {
                }
            }
            .tableCustomerName {
            }
            .tableLoanId {
            }
        }
        .CollectionAmtCol {
        }
        .Paid {
        }
        .Unpaid {
        }
        .NextDue {
        }
    }
    
    .lineChart { 
    }
    
    .piechartLayout {
        width: 84%;
        margin-top: 3%;
        padding: 1% 1%;
        .piechart {
        }
    }
    
    .tableLayout {
        .tableHead {
            .headRow {
                .header {
                }
            }
        }
        .tableBodyRow {
            .tablePrimaryCol {
                .tableImgDiv {
                    .tableImg {
                    }
                }
                .nameLayout {
                    .tableCustomerName {
                    }
                    .tableLoanId {
                    }
                }
            }
        }
    }
    
    .dropdownbuttonlayout {
        .dropdownButton {
            .dropdownIcon {
            }
        }
        .hoverMenu {
            .menuItem {
                .floatRightIcon {
                }
            }
            .menuItem:hover {
            }
        }
    }
    
    .dropdownbuttonlayout:hover {
        .hoverMenu {
        }
    }
    
    .collectionAgentlayout {
        .collectionAgentButton {
            .userSolid{
            }
            .greenArrow {
            }
        }
        .hoverMenu {
            .menuItem {
                .floatRightIcon {
                }
            }
            .menuItem:hover {
            }
        }
    }
    
    .collectionAgentlayout:hover {
        .hoverMenu {
        }
    }
    
    .lineChartLayout {
        width: 84%;
        .lineChartHeader {
        }
    }
    
    .pieHeader {
        .pieHead {
        }
        .pieHeadDropdown {
        }
    }
    
    .amountDetailLayout {
    }
    
    .amountDetail {
        .adHead {
        }
        .adBody {
        }
    }
    
    .amountDetailActive {
    }
    
    .innerLayout {
    }
    
    .bold {
    }
    
    .defaultCursor {
    }
    .recharts-legend-item-text {
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .dashboard {
        .progressCards {
            .scColorYellow {
            }
            .scColorGreen {
            }
            .scColorPurple {
            }
            .scColorPink {
            }
            .scInactiveCard {
            }
        }
        .graphLayout {
            display: flex;
        }
    }
    
    .header {
        .filterLayout {
        }
    }
    
    .statusCard {
        .scInnerLayout {
            .scHeader {
            }
            .scBody {
                .circularBar {
                }
                .scValues {
                    .mainAmountVal {
                    }
                }
            }
        }
    }
    
    .table {
        .tablHeadRow {
        }
        .tablePrimaryCol {
            .tableImgDiv {
                .tableImg {
                }
            }
            .tableCustomerName {
            }
            .tableLoanId {
            }
        }
        .CollectionAmtCol {
        }
        .Paid {
        }
        .Unpaid {
        }
        .NextDue {
        }
    }
    
    .lineChart { 
    }
    
    .piechartLayout {
        width: 41%;
        padding: 1% 1%;
        .piechart {
        }
    }
    
    .tableLayout {
        .tableHead {
            .headRow {
                .header {
                }
            }
        }
        .tableBodyRow {
            .tablePrimaryCol {
                .tableImgDiv {
                    .tableImg {
                    }
                }
                .nameLayout {
                    .tableCustomerName {
                    }
                    .tableLoanId {
                    }
                }
            }
        }
    }
    
    .dropdownbuttonlayout {
        .dropdownButton {
            .dropdownIcon {
            }
        }
        .hoverMenu {
            .menuItem {
                .floatRightIcon {
                }
            }
            .menuItem:hover {
            }
        }
    }
    
    .dropdownbuttonlayout:hover {
        .hoverMenu {
        }
    }
    
    .collectionAgentlayout {
        .collectionAgentButton {
            .userSolid{
            }
            .greenArrow {
            }
        }
        .hoverMenu {
            .menuItem {
                .floatRightIcon {
                }
            }
            .menuItem:hover {
            }
        }
    }
    
    .collectionAgentlayout:hover {
        .hoverMenu {
        }
    }
    
    .lineChartLayout {
        width: 43%;
        .lineChartHeader {
            display: block;
            font-size: 12px;
        }
    }
    
    .pieHeader {
        .pieHead {
        }
        .pieHeadDropdown {
        }
    }
    
    .amountDetailLayout {
    }
    
    .amountDetail {
        .adHead {
        }
        .adBody {
            font-size: 10px;
        }
    }
    
    .amountDetailActive {
    }
    
    .innerLayout {
    }
    
    .bold {
    }
    
    .defaultCursor {
    }
    .recharts-legend-item-text {
    }
  }
  @media only screen and (min-width: 0px) and (max-width: 600px) {
    .header {
        .filterLayout {
            flex-direction: column !important;
        }
    }
    .dashboard {
        padding: 0 !important;
    }
    .graphLayout {
        height: 30% !important;
        width: 100% !important;
    }
    .amountDetailLayout {
        display: flex;
        flex-direction: column !important;
        margin-top: -50% !important;
    }
  }