.infoCardComponent {
  .infoCard {
    text-transform: capitalize;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 21px 0;
    color: black;
    border-radius: 5px;
    font-size: 150%;
    // width: 199px;
    // margin: 3rem;
    .cardContent {
      display: block;
      border-radius: 10px;
      padding: 9px 5px;
      width: 100%;
      box-shadow: 0px 0px 5px 1px rgba(224, 221, 221, 0.66);
      .infocardmainDiv {
        display: flex !important;
        .cardDetail {
          display: block;
          padding-left: 15px;
          width: 17%;
          .h4heading {
            text-align: left;
            width: 100%;
            margin-bottom: 0;
            font-size: 17px;
          }
          .pHeading {
            font-size: 13px;
            text-align: left;
            color: rgb(168, 168, 168);
            margin-top: 5px;
          }
        }
        .duration {
          width: 25% !important;
        }
      }
    }
  }
  .headerPart {
    display: flex;
    margin: 21px 0px;
    width: 100%;
    height: auto;
    .headerLeft {
      width: 50%;
      .headerPartOne {
        min-width: 150px;
        display: block;
        .headerPartOneOne {
          text-align: left;
          font-size: 23px;
          font-weight: 700;
          color: rgb(49, 46, 46);
        }
        .headerPartOneTwo {
          text-align: left;
          font-size: 13px;
          color: rgb(168, 168, 168);
        }
      }
    }
    .headerRightSide {
      display: flex;
      min-width: 50%;
      justify-content: flex-end;
      padding-top: 6px;
      .headerPartTwo {
        display: block;
        width: 160px;
        margin: 0 1rem;
        .headerPartTwoOne {
          font-size: 16px;
          font-weight: 700;
          color: rgb(49, 46, 46);
        }
        .headerPartTwoTwo {
          color: rgb(168, 168, 168);
          font-size: 13px;
        }
      }
      .headerPartThree {
        display: block;
        width: 155px;
        display: block;
        padding-left: 50px;
        .headerPartThreeOne {
          font-size: 15px;
          font-weight: 700;
          color: #312e2e;
          width: 100%;
          text-align: left;
        }
        .headerPartThreeTwo {
          font-size: 13px;
          color: rgb(168, 168, 168);
          text-align: left;
        }
      }
      .headerPartFour {
        padding-left: 40px;
        .cancelBtn {
          cursor: pointer;
          background-color: #00d95e;
          font-size: 12px;
          color: #fff;
          font-weight: 500;
          z-index: -1;
          padding: 6px 12px;
          border-radius: 5px;
          border: none;
        }
        .disableLoanButton {
          background-color: #d1f6e4 !important;
          border: none !important;
        }
      }
    }
  }
  .loanProgress {
    width: 100%;
    padding: 20px 0px 20px 25px;
    box-sizing: border-box;
    .progress {
      display: flex;
      justify-content: center;
      .circularBar {
        height: 100%;
        width: 85px;
        margin: auto;
      }
      .MainAmountDisplay {
        display: flex;
        width: 52rem;
        justify-content: center;
        .amountDisplay {
          background-color: #4d66f0;
          font-weight: 600;
          padding: 15px 25px;
          margin: 6px;
          border-radius: 10px;
          width: 200px;
          font-size: 30px;
          height: 65px;
          color: #fff;
          box-shadow: 0px 0px 8px 1px lightgrey;
          .type {
            color: white;
            font-size: 14px;
            font-weight: 200;
          }
        }
      }
    }
  }
}

.cancelLoan {
  height: 100vh;
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
}
