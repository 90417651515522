.Editcompanyprofilecomponent{
    background-color: #FBFCFF;
.cardDiv {
    margin: 5.6%;
    margin-top: 0%;
    margin-bottom: 28px;
    margin-left: 11%;
    padding-bottom: 3rem;
.imgPreview{
  text-align: center;
  margin: auto;
  margin-top:5%;
  height: 134px;
  width: 134px;
  border: 1px solid #D4D4D5;
  border-radius:100px;
  position: relative;
  img{
        width: 134px;
        height: 134px;
        border-radius:100px;
      }
  .fileInput{
    cursor: pointer;
    border-radius:100px;
    height: 134px;
    width: 134px;
    opacity:0;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
.MuiInput-root {
    position: relative;
    // border: 1px solid #D4D4D5!important;
    width: 29%;
    border-radius: 5px;
}
.fontSize{
    font-size: 13px ;
}
.One{
margin-left: 31px;
}
.Two{
    margin-left: 49px;
}
.Three{
    margin-left: 30px;
}
.Four{
    margin-left: 9px;
}
.Five{
    margin-left: 9px;
}
.Six{
    margin-left: 10px;
}
.InputLabel{
    width: 74%;
    margin-bottom: 10px;
    font-weight: 400;
    margin-top:20px;
    color: #8E8E98;
}
.MuiFormControl-root{
    width: 29%;
}
.cameradefault {
    position: absolute;
    left: 53%;
    bottom: 11%;
}
.categorytextFieldStyle {
    // #standard-select {
        .MuiInput-root{
            width:100%;
            height: 34px;        
         
        // }
    }
    .MuiSelect-select{
        text-align: left;
    }
    .MuiSelect-select:focus{
        background-color: transparent;
    }
}
.savebutton {
    background-color: #00D95E;
    text-transform: capitalize;
    color: #fff;
    height: 33px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 2px;
    position: relative;
    left: 45px;
}
.cancelbutton {
    border: 1px solid #E4E4E4;
    text-transform: capitalize;
    color: #8E8E98;
    height: 33px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 2px;
    margin: 3%;
    position: relative;
    left: 45px;
}
}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (min-width: 320px) and (max-width: 480px) {
    .Editcompanyprofilecomponent{
        .One{
            margin-left: 33px !important;
            
            }
            .Two{
                margin-left: 47px !important;
            }
            .Three{
                margin-left: 32px !important;
            }
            .Four{
                margin-left: 15px !important;
            }
            .Five{
                margin-left: 15px !important;
            }
            .Six{
                margin-left: 16px !important;
            }
            .fontSize{
                font-size: 11px !important;
            }
            
    }
}
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-device-width: 481px) and (max-device-width: 767px) and (min-width: 481px) and (max-width: 767px) {
    .Editcompanyprofilecomponent{
        .One{
            margin-left: 35px !important;
            }
            .Two{
                margin-left: 51px !important;
            }
            .Three{
                margin-left: 34px !important;
            }
            .Four{
                margin-left: 14px !important;
            }
            .Five{
                margin-left: 14px !important;
            }
            .Six{
                margin-left: 14px !important;
            }
            .fontSize{
                font-size: 12px !important;
            }
            
    }
}    
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width: 320px) {
    .Editcompanyprofilecomponent{
        .One{
            margin-left: 28px !important;
            
            }
            .Two{
                margin-left: 39px !important;
            }
            .Three{
                margin-left: 27px !important;
            }
            .Four{
                margin-left: 15px !important;
            }
            .Five{
                margin-left: 15px !important;
            }
            .Six{
                margin-left: 16px !important;
            }
            .fontSize{
                font-size: 9px !important;
            }
            
    }
   
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
    .Editcompanyprofilecomponent{
        .One{
            margin-left: 38px !important;
            }
            .Two{
                margin-left: 54px !important;
            }
            .Three{
                margin-left: 37px !important;
            }
            .Four{
                margin-left: 16px !important;
            }
            .Five{
                margin-left: 16px !important;
            }
            .Six{
                margin-left: 17px !important;
            }
            .fontSize{
                font-size: 13px !important;
            }
    }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    /* STYLES GO HERE */
  }
  
  /* Desktops and laptops ----------- */
  @media only screen and (min-width: 1224px) {
    /* STYLES GO HERE */
  }
  
  /* Large screens ----------- */
  @media only screen and (min-width: 1824px) {
    /* STYLES GO HERE */
  }
  
  /* iPhone 5 (portrait &amp; landscape)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) {
    /* STYLES GO HERE */
  }
  
  /* iPhone 5 (landscape)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
    /* STYLES GO HERE */
  }
  
  /* iPhone 5 (portrait)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
    /* STYLES GO HERE */
  }