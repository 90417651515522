.messagedialogbox{
    .messagedialogboxContainer{
        width: 500px;
    .TitleDiv{
        width:450px;
        // text-align: center;
        .deleteconfirmation{
            .messagePopuptextArea{
                width: 100%;
                height: 100px !important;
                border: 1px solid lightgray;
                border-radius: 5px;
                 
            }
            .confrmmessage{
            }
        }
    }
    .bottomPart{
        border: 1px solid white;
        border-top-color: 1px solid lightgrey;
      .bottomPartOne{
          float:right;
        .cancelbutton{
margin-right: 1rem;
text-transform: capitalize;
        }
        .savebutton{
background-color: #00D95E;
text-transform: capitalize;
color: #fff;
        }}
    }}
}