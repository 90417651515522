body {
  margin: 0;
  background-color: #FBFCFF;
  height: 100vh;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  overflow: auto !important;
}

@font-face {
  font-family: 'Comic Sans MS';
  src: url('/public/ComicSansMS.ttf');
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }
.Fonts {
  font-family: 'Lato'!important;
  }
  .HighlightTextColour {
    color: #00D95E!important;
  }
  .TextColour {
    color: #3E4664!important;
  }
  .SizeFont {
    font-size: 14px!important; 
  }
  .btnSizeFont {
    font-size: 16px!important;
  }
  .linkFontSize {
    font-size: 12px!important; 
  }
  .largeFontSize {
    font-size: 18px!important; 
  }
  .headFontSize {
    font-size: 20px!important; 
    font-weight: 600!important;
    color: #3E4664!important;
  }
  .btnbackgroundcolor {
    background-color: #00D95E!important;
  }
  .MuiInput-underline:before{
    border-bottom:none!important;
}

.MuiInput-underline:after{
    border-bottom:none!important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type='number'] {
  -moz-appearance:textfield;
}
// .MuiSelect-icon{
//   // display: none !important;
//   top: calc(50% - 15px) !important;
// }
// .MuiAvatar-root{
//   position: none !important;
// }


// .buttonheight  {
//   height: 42px;
//   padding-left: 20px;
//   padding-right: 20px;
// }
  
.react-date-picker__wrapper {
    border: 1px solid lightgrey !important;
    width: 23.5rem !important;
    height: 42px !important;
    border-radius: 5px !important
}
.react-date-picker__clear-button{
  display:none;
}
.internet-error {
  height: 40px;
  background: #cc0000;
  margin-top: 0;
  font-size: 20px;
  text-align: center;
}

.internet-error p {
  font-size: 20px;
  line-height: 38px;
  color: #fff;
  margin: 0;
  font-family: 'Lato'!important;

}