.sidepage {
  .fa {
    position: relative;
    display: block;
    text-align: center;
    vertical-align: middle;
  }

  .borderLeft {
    height: 100%;
  }

  .liClass {
    position: relative;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .main-menu {
    background: #4d66f0;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    width: 73px;
    overflow: auto;
    transition: width 0.3s ease-in-out; /* Add a smooth transition for the width */
    z-index: 1000;
    .liClass img {
      width: 22px;
      height: 22px;
    }
  }

  .main-menu:hover {
    width: 180px !important;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out; /* Add smooth transition for img */
    .liClass img {
      width: 22px;
      height: 22px;
    }
    .liClass h6 {
      width: 100px;
    }
  }

  .main-menu h6 {
    display: none;
  }

  .main-menu:hover h6 {
    display: block;
  }

  .main-menu > ul {
    margin: 7px 0;
    margin-top: 38px;
  }

//   .main-menu li {
//     position: relative;
//     display: block;
//     width: 100%;
//   }

  .main-menu li a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Arial, sans-serif;
    font-size: 14px;
    text-decoration: none;
    transition: all 0.1s linear;
  }

  .menu-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .menu-icon {
    width: 30px;
    height: 30px;
  }

  .menu-text {
    color: white;
    font-size: 15px;
    margin-top: 5px;
  }

//   .main-menu > ul.logout {
//     position: absolute;
//     left: 0;
//     bottom: 0;
//   }

  a:hover,
  a:focus {
    text-decoration: none;
  }

  nav {
    user-select: none;
  }

  nav ul,
  nav li {
    outline: 0;
    margin: 0;
    padding: 0;
  }

  .main-menu li:hover > a,
  nav.main-menu li.active > a {
    color: #fff;
    cursor: pointer;
  }

  .area {
    float: left;
    background: #e2e2e2;
    width: 100%;
    height: 100%;
  }
}
