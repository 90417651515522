.demand-promissory-note {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 20px;
}

.header-left {
  text-align: center;
}

.header-left .logo {
  width: 100px;
  height: 120px;
  padding: 10px 0px 8px 0px;
}

.header-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-right h1 {
  margin: 0;
  font-size: 24px;
  text-align: center;
}

.header-right p {
  margin: 5px 0 0;
  font-size: 14px;
  text-align: center;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
}

.info-table td {
  border: 1px solid #000;
  padding: 5px;
  font-size: 15px;
  text-align: center;
  width: 25%;
}

.input-line {
  display: inline-block;
  width: 180px;
  border-bottom: 1px dotted #000;
  margin-left: 5px;
  margin-right: 5px;
}

h3 {
  margin: 10px 0;
  font-size: 16px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

@media print {
  .page-break {
    page-break-before: always;
  }

  .demand-promissory-note {
    page-break-inside: avoid;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .section {
    page-break-inside: avoid;
  }

  .footer-table {
    width: 90% !important;
    margin-right: 5% !important;
  }
}
