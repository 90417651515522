.print-docs {
  font-family: "Lato", sans-serif !important;
  border-radius: 5px;
  margin: 0 auto !important;
  background: #f2f2f2;
  box-shadow: 0 2px 8px rgba(98, 96, 96, 0.2);
}

.paddingSidebar {
  padding: 10px 20px 10px 100px;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .paddingSidebar {
    padding: 0;
  }

  .page-break {
    page-break-before: always;
  }

  .loan-agreement {
    page-break-inside: avoid;
  }

  .info-table {
    page-break-inside: avoid;
  }
  
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  /* Prevents elements from breaking */
  .section {
    page-break-inside: avoid;
  }
}
