.mainDiv {
    // width: 406px !important;
    
    .MuiDialog-paperWidthSm{
        position: absolute!important;
    left: 42px!important;
    bottom: -4px!important;
    }
    .TitleDiv {
        width: 389px !important; 
        padding: 15px 0px;
        padding-left: 17px;
        .profileimagepart {
            text-align: center;
            margin-left: 18px;
            margin-top: 10px;
        }
        .editimagepart {
            // text-align: center;
            // margin-left: 18px;
            margin-top: 10px;
            cursor: pointer;
        }
        .ProfilePersonNameDiv {
            text-align: center;
            .ProfilePersonName {
                margin-top: 0px;
                margin-bottom: 0px;
                font-size: 20px;
                color: #3E4664;
                font-weight: 400;
                text-transform: capitalize;
            }
            .languagetext{
                margin-top: 0px;
                color:#8E8E98;
                margin-bottom: 10px;
                font-weight: 300
            }
        
        }
        .emailcontainer {
            .emailtext {
                color:#3E4664;
                margin-bottom: 0px;
                margin-top: 17px;
                font-weight: 300
            }
            .phonetext{
                margin-bottom: 0px;
                margin-top: 0px;
                color:#8E8E98;
                font-weight: 300
            }
        }
        .signoutbuttonDiv {
            position: relative!important;
            text-align: center;
            width: fit-content;
            .signoutbutton {
                position: absolute!important;
                top: 25%!important;
                // left: 25%!important;
                text-transform: capitalize;
                height: 30px;
                text-align: center;
                color: #ffff;
                padding-top: 3px;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
    .bottomPart {
        background-color: #F2FBF6;
        padding: 8px 19px;
        // margin-top: 90px;
        .companyprofile {
            display: flex;
            .companyprofiletext {
                margin: 15px;
                font-size: 16px;
                font-weight: 400;
            }
            .companyprofiletext:hover {
                margin: 15px;
                font-size: 16px;
                font-weight: 400;
                color: #00D95E;
            }
        }
        .usermanagement {
            display: flex;
            cursor: pointer;
            .usermanagementtext {
                margin: 15px;
                font-size: 16px;
                font-weight: 400;
            }
            .usermanagementtext:hover {
                margin: 15px;
                font-size: 16px;
                font-weight: 400;
                color: #00D95E;
            }
        }
        .companyprofile {
            display: flex;
            cursor: pointer;
            .companyprofiletext {
                margin: 15px;
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
}