.addusercomponent{
    background-color: #FBFCFF;
.cardDiv {
    margin: 5.6%;
    margin-top: 0%;
    margin-bottom: 28px;
    margin-left: 11%;
.imgPreview{
  text-align: center;
  margin: auto;
  margin-top:5%;
  height: 134px;
  width: 134px;
  border: 1px solid #D4D4D5;
  border-radius:100px;
  position: relative;
  img{
        width: 134px;
        height: 134px;
        border-radius:100px;
      }
  .fileInput{
    // padding: 10px;
    // margin: 130px;
    cursor: pointer;
    border-radius:100px;
    height: 134px;
    width: 134px;
    opacity:0;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
.MuiInput-root {
    position: relative;
    // width: 29%;
    border-radius: 5px;
}
.fontSize{
    font-size: 13px ;
}
.One{
margin-left: 31px;
}
.Two{
    margin-left: 49px;
}
.Three{
    margin-left: 30px;
}
.Four{
    margin-left: 9px;
}
.Five{
    margin-left: 9px;
}
.Six{
    margin-left: 10px;
}
.InputLabel{
    width: 74%;
    margin-bottom: 10px;
    font-weight: 400;
    margin-top:20px;
    color: #8E8E98;
}
.MuiFormControl-root{
    width: 29%;
}
.cameradefault {
    position: absolute;
    left: 52.5%;
    bottom: 0%;
}
.categorytextFieldStyle {
    // // #standard-select {
    //     .MuiInput-root{
    //         width:100%;         
    //     // }
    // }
    .MuiSelect-select{
        text-align: left;
    }
    .MuiSelect-select:focus{
        background-color: transparent;
    }
}
.savebutton {
    background-color: #00D95E;
    text-transform: capitalize;
    color: #fff;
    height: 33px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 2px;
    position: relative;
    left: 45px;
}
.cancelbutton {
    border: 1px solid #E4E4E4;
    text-transform: capitalize;
    color: #8E8E98;
    height: 33px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 2px;
    margin: 3%;
    position: relative;
    left: 45px;
}
// .textBox:focus {
//     border: 1px solid #00D95E;
// }
// .textBox:active {
//     border: 1px solid #00D95E;
// }
//   img{
//     width: 100%
//     height: 100%
//   }
  
  
// .submitButton{
//   padding: 12px;
//   margin-left: 10px;
//   background: white;
//   border: 4px solid lightgray;
//   border-radius: 15px;
//   font-weight: 700;
//   font-size: 10pt;
//   cursor: pointer;
//   &:hover
//     background: #efefef;
// }
// .centerText{
//   text-align: center;
//   width: 500px;
// }
}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (min-width: 320px) and (max-width: 480px) {
    .addusercomponent{
        .One{
            margin-left: 21px !important;
            
            }
            .Two{
                margin-left: 21px !important;
            }
            .Three{
                margin-left: 32px !important;
            }
            .Four{
                margin-left: 15px !important;
            }
            .Five{
                margin-left: 17px !important;
            }
            .Six{
                margin-left: 38px !important;
            }
            .Seven{
                margin-left: 16px !important;
            }
            .fontSize{
                font-size: 11px !important;
            }
            
    }
}
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-device-width: 481px) and (max-device-width: 767px) and (min-width: 481px) and (max-width: 767px) {
    .addusercomponent{
        .One{
            margin-left: 21px !important;
            
            }
            .Two{
                margin-left: 21px !important;
            }
            .Three{
                margin-left: 32px !important;
            }
            .Four{
                margin-left: 15px !important;
            }
            .Five{
                margin-left: 17px !important;
            }
            .Six{
                margin-left: 38px !important;
            }
            .Seven{
                margin-left: 16px !important;
            }
            .fontSize{
                font-size: 12px !important;
            }
            
    }
}    
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width: 320px) {
    .addusercomponent{
        .One{
            margin-left: 18px !important;
            
            }
            .Two{
                margin-left: 18px !important;
            }
            .Three{
                margin-left: 29px !important;
            }
            .Four{
                margin-left: 14px !important;
            }
            .Five{
                margin-left: 15px !important;
            }
            .Six{
                margin-left: 33px !important;
            }
            .Seven{
                margin-left: 14px !important;
            }
            .fontSize{
                font-size: 9px !important;
            }
            .savebutton {
                background-color: #00D95E !important;
                text-transform: capitalize !important;
                color: #fff !important;
                height: 28px !important;
                padding-left: 20px !important;
                padding-right: 20px !important;
                padding-top: 2px !important;
                position: relative !important;
                left: 20px !important;
            }
            .cancelbutton {
                border: 1px solid #E4E4E4 !important;
                text-transform: capitalize !important;
                color: #8E8E98 !important;
                height: 28px !important;
                padding-left: 15px !important;
                padding-right: 15px !important;
                padding-top: 2px !important;
                margin: 3% !important;
                position: relative !important;
                left: 20px !important;
            }
            
    }
   
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
    .addusercomponent{
        .One{
            margin-left: 21px !important;
            
            }
            .Two{
                margin-left: 21px !important;
            }
            .Three{
                margin-left: 32px !important;
            }
            .Four{
                margin-left: 15px !important;
            }
            .Five{
                margin-left: 17px !important;
            }
            .Six{
                margin-left: 42px !important;
            }
            .Seven{
                margin-left: 16px !important;
            }
            .fontSize{
                font-size: 13px !important;
            }
    }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    /* STYLES GO HERE */
  }
  
  /* Desktops and laptops ----------- */
  @media only screen and (min-width: 1224px) {
    /* STYLES GO HERE */
  }
  
  /* Large screens ----------- */
  @media only screen and (min-width: 1824px) {
    /* STYLES GO HERE */
  }
  
  /* iPhone 5 (portrait &amp; landscape)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) {
    /* STYLES GO HERE */
  }
  
  /* iPhone 5 (landscape)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
    /* STYLES GO HERE */
  }
  
  /* iPhone 5 (portrait)----------- */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
    /* STYLES GO HERE */
  }

  @media only screen and (min-width: 0px) {
    .dynamicWidth {
        width: 80% !important;
    }
  }

  @media only screen and (min-width: 400px) {
    .dynamicWidth {
        width: 70% !important;
    }
  }

  @media only screen and (min-width: 800px) {
    .dynamicWidth {
        width: 50% !important;
    }
  }

  @media only screen and (min-width: 1100px) {
    .dynamicWidth {
        width: 35% !important;
    }
  }

  @media only screen and (min-width: 1400px) {
    .dynamicWidth {
        width: 30% !important;
    }
  }

  @media only screen and (min-width: 0px) {
    .numberInputW {
        width: 70% !important;
    }
  }

  @media only screen and (min-width: 400px) {
    .numberInputW {
        width: 60% !important;
    }
  }

  @media only screen and (min-width: 800px) {
    .numberInputW {
        width: 40% !important;
    }
  }

  @media only screen and (min-width: 1100px) {
    .numberInputW {
        width: 30% !important;
    }
  }

  @media only screen and (min-width: 1400px) {
    .numberInputW {
        width: 25% !important;
    }
  }
