.loadingPage {
  height: 100vh;
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
  // opacity: 0.8;

  .loadBall {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
