// . daterange {
// .GoButton{
//     background-color: #00D95E;
// }
// }
.daterange {
    .MuiDialog-paperScrollPaper {
        padding: "5px"
    }
}

.invalidDate {
    font-size: 12px;
    color: red;
}