.Allcustomercardviewpage{
    display:flex;
    margin-left: 6.3rem;
    .MuiCardHeader-root{
        padding: 16px !important;
    }
    .CardDiv {
        margin:1.1rem;
        width:270px; 
        height:auto !important;
        border-radius:10px;
        .MuiAvatar-root{
            border-radius: 25%;
            position: unset;
        }
        .NameDiv {
            font-size: 18px;
            font-weight: 900;
            font-family: 'Montserrat';
            color: rgb(64, 72, 109);
            text-transform: capitalize;
        }
        .subheadingnameDiv {
            font-size: 12px;
            font-weight: 400;
            font-family: 'Montserrat';
            color: #8E8E98
        }
        .MuiCardContent-root {
            padding: 0px
        }
        .MoneydetailsDiv {
            color:#757575 !important;
            font-size: 18px;
            font-weight: 900 !important;
        }
        .subheaderDiv {
            display:flex;
            .subheaderStatus{
                // for overdue: #ffe5e5
                // for due: #edeffe
                // background-color:transparent;   
                border-radius: 5px;
                width: fit-content;
                padding: 2px;
                // color:#FF0000;
                font-size: 14px;
                font-weight: 900 !important;
            }
            .subheaderDays {
                color: #757575;
                font-size: 14px;
                font-weight: 900 !important;
                padding-left: 8px;
                padding-top: 1.5%;
            }
        }
        .footerDiv1 {
            width: 67%;
            .footerDivdate{
                color: #3E4664;
                font-size: 14px;
                font-weight: 900;
            }
            .footerDivdatedetails{
                color: #8E8E98;
                font-size: 12px;
                font-weight: 400;

            }
        }
        .footerDiv2{
            .footerDivmonth{
                color: #3E4664;
                font-size: 14px;
                font-weight: 900;
                text-transform: capitalize;
            }
            .footerDivmonthdetails{
                color: #8E8E98;
                font-size: 12px;
                font-weight: 400;
            }
        }
        .loantypename{
            color: #8E8E98;
            font-size: 11px;
        }

    }
    .CardDiv:hover {
        background-image: linear-gradient(to bottom right, #00D95E ,#4277DB );
        cursor: pointer;
        .NameDiv {
            font-size: 18px;
            font-weight: 900;
            font-family: 'Montserrat';
            color: #ffff;
        }
        .subheadingnameDiv {
            font-size: 12px;
            font-weight: 400;
            font-family: 'Montserrat';
            color: #ffff;
                           
        }
        .MuiCardContent-root {
            padding: 0px
        }
        .MoneydetailsDiv {
            color:#ffff !important;
            font-size: 18px;
            font-weight: 900 !important;
        }
        .subheaderDiv {
            display:flex;
            .subheaderStatus{
                background-color:transparent;
                border-radius: 5px;
                width: fit-content;
                padding: 2px;
                color:#ffff;
                font-size: 14px;
                font-weight: 900 !important;
            }
            .subheaderDays {
                color: #ffff;
                font-size: 14px;
                font-weight: 900 !important;
                padding-left: 8px;
                padding-top: 1.5%;
            }
        }
        .footerDiv1 {
            width: 67%;
            .footerDivdate{
                color: #ffff;
                font-size: 14px;
                font-weight: 900;
            }
            .footerDivdatedetails{
                color: #ffff;
                font-size: 12px;
                font-weight: 400;

            }
        }
        .footerDiv2{
            .footerDivmonth{
                color: #ffff;
                font-size: 14px;
                font-weight: 900;
                text-transform: capitalize;

            }
            .footerDivmonthdetails{
                color: #ffff;
                font-size: 12px;
                font-weight: 400;
            }
        }
        .loantypename{
            color: #ffff;
            font-size: 11px;
        }
    }
}

.absolute-container2 {
    position: relative;
    width: 100%;
    height: 500px;
    margin-top: 50px;
  }
  
  .centered-image2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centers the image */
    max-width: 100%; /* Ensures image is responsive */
    max-height: 100%; /* Ensures image is responsive */
  }
  

@media only screen and (min-device-width: 0px) and (max-device-width: 959px) {
    .Allcustomercardviewpage{
       
        margin-left: 5rem !important;
    }
  }