.customerdetailsinfo {
  height: 100%;
  width: 100%;

  .MainDiv {
    display: flex;
    // height: 100%;
    .firstcomponent {
      background-color: #4d66f0;
      padding: 10px;
      min-height: 96vh;
    }
    .secondComponent {
      width: 71%;
      .infoLayout {
        margin: auto;
        width: 97%;
      }
    }
  }
}
