.collectionreportheadercomponent {
  .Customerheader {
    width: 100%;
    background-color: #ffffff;
    .MuiButton-root:hover {
      background-color: transparent !important;
    }
    .bar {
      background-color: transparent !important;
      box-shadow: none !important;
      .title {
        text-align: left;
        width: 31%;
        margin-left: 97px;
      }
      .OptionDiv1 {
        width: 37%;
        display: flex;
        cursor: pointer;
        .option {
          padding: 20px;
          text-decoration: none;
          color: #d4d4d5;
        }
        .option:hover {
          padding: 20px;
          text-decoration: none;
          color: #4d66f0 !important;
        }
      }
      .searchDiv {
        text-align: left;
        width: 22%;
        background-color: #eff2ff;
        border-radius: 8px;
      }
    }
  }

  .CustomerfilterReport{
    width: 100%;
    // margin-top: 15px;
    // background-color: #FFFFFF;
    // padding-top: 15px;
    .MuiButton-root:hover{
        background-color: transparent !important;
    }
    .bar {
        background-color: transparent!important;
        // color: black!important;
        box-shadow: none!important;
        .title{
            text-align: left;
            width: 19%;
            padding-left: 6rem;
            .Tabbutton {
                border: none;
                color: #757575;
                text-transform: capitalize;
            }
        }
        .OptionDiv1{
            width: 60%;
            text-align: end;
            display: flex;
            padding-right: 1.1rem;
            justify-content: flex-end;
            .textBox{
                background-color: #EFF2FF;
                border-radius: 5px;
                padding-top: 3px;
            }
           
        }
      
           
            .dropdown-menu {
                position: absolute !important;
                will-change: transform !important;
                top: 0px !important;
                left: 0px !important;
                transform: translate3d(-44px, 34px, 0px) !important;
                background-color: #3E4664;
                .dropdown-item{
                    color: #fff;
                    font-weight: 400
                }
                .dropdown-item:hover {
                    background-color: #757575;
                }
            }
            .dropdown-toggle::after {
                display: none;
            }
            .ButtonDiv {
                // position: absolute;
                // right: 50px; 
                // top: 14px;
                padding-left: 20px;
                .adduserbutton {
                    text-transform: capitalize;
                    color: #ffff !important;
                    padding-left: 15px;
                    padding-right: 15px;
                    border-radius: 5px;
                    height: 34px;
                    padding-top: 4px;
                    background-color: #00D95E !important;
                    color: #fff !important;
                    .plusiconButton{
                        padding-right: 5px;
                    }
                }
                .adduserbutton:hover {
                    text-transform: capitalize;
                    color: #ffff;
                    padding-left: 15px;
                    padding-right: 15px;
                    border-radius: 5px;
                    height: 34px;
                    padding-top: 4px;
                    background-color: #00D95E !important;
                    .plusiconButton{
                        padding-right: 5px;
                    }
                }
            }
            .option:hover{
                padding: 20px;
                text-decoration: none;
                color: #4D66F0 !important;
            }
        }
        .imagediv {
            padding-left: 15px;
            padding-top: 8px;
        }
        .gridDiv{
            padding-left: 15px;
            padding-top: 8px;
        }
    
       .MuiInputBase-input{
                padding: 0px 24px 8px 11px !important;
                font-size: 15px !important;
                // color: #3E4664 !important;
                font-family: "Lato" !important;
            }
            .MuiSelect-select:focus {
                background-color: transparent;
                color: #00D95E !important
            }
            // .MuiSelect-select{
            //     color: #00D95E !important
            // }
            .cardtypeicon{
                cursor: pointer;
            }
            .gridtypeicon {
                cursor: pointer;
            }
            // .MuiButton-root{
            //     color: #fff !important;
            // }
            .filterDrop {
                background-color:transparent;
                border: 1px solid #E4E4E4;
                color: #3E4664;
                height: 36px;
                .filtericon{
                    padding-left: 15px;
                    padding-bottom: 3px 
                }
            }
            .filterDrop:focus{
                background-color: transparent;
                border: 1px solid #00D95E;
                color: #00D95E;
                box-shadow: none !important;
            }
            .timeDrop {
                background-color: #EFF2FF;
                border: none;
                color: #3E4664;
                height: 36px;
                text-transform: capitalize;
                border-radius: 5px;
                margin-right: 8px;
                text-transform: capitalize;
                // .calendertimeicon{
                //     padding-left: 15px;
                //     padding-bottom: 3px 
                // }
        
            .timeDrop:focus{
                background-color: #EFF2FF;
                border: 1px solid #00D95E;
                color: #00D95E;
                box-shadow: none !important;
            }
           
}
}

  .MuiMenu-paper {
    color: #fff !important;
    background-color: #3a3b3d !important;
  }
  .menuItemList {
    display: flex;
    padding: 8px;
    cursor: pointer;
    justify-content: space-between;
  }
  .menuItem {
    position: absolute;
    margin-right: 10px;
    right: 30px;
    height: 22px;
    width: 23px;
  }
  .ulClass {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .ulClass .liClass {
    display: block;
    position: relative;
    float: left;
    background: #feffff;
  }
  .liClass .ulClass {
    display: none;
  }
  .ulClass .liClass .aClass {
    display: block;
    padding: 4px 1em 8px;;
    text-decoration: none;
    white-space: nowrap;
    position: relative;
    color: rgb(12, 10, 10);
  }
  .second_ul {
    border-radius: 5px;
  }
  ul .liClass .aClass:hover {
    background: #50648c;
  }
  .textBox {
    background-color: #EDEFFE !important;
    color: black !important;
  }
  .textBoxFilter{
    background-color: transparent;
    color: lightgrey !important;
    border:1px solid lightgrey ;

  }
  .main-navigation :hover {
    background: #2b3d63;
    color: white;
    // border-radius: 5px;
  }
  .main-navigation {
    color: white;
    // border-radius: 5px;
  }
  .liClass:hover > .ulClass {
    display: block;
    margin-top: 1px;
    margin-right: 1px;
    //   border-radius: 5px !important;
    position: absolute;
    border: 2px;
    box-shadow: #122344 1px 0px 5px 1px;
  }
  .liClass:hover .liClass {
    float: none;
  }
  .liClass:hover .aClass {
    background: #2b3d63;
    color: white;
    text-align: left;
    white-space: nowrap;
    border-radius: inherit;
  }
  .liClass:hover .liClass .aClass:hover {
    background: #50648c;
  }
  .main-navigation .liClass .ulClass .liClass {
    border-top: 0;
  }
  .ulClass .ulClass .ulClass {
    right: 100%;
    top: 0;
    max-height: 350px;
    overflow-y: scroll;
  }
  .ulClass:before,
  .ulClass:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  .ulClass:after {
    clear: both;
  }

  .menuIcon {
    float: right;
  }

  .userIcon {
    margin-right: 10px;
  }
}


.caMenuItem{
  background: blue;
  display: flex;
  justify-content: space-between;
}