.addcustomercomponent{
    background-color: #FBFCFF;
.cardDiv {
    margin: 0 5% 1% 10%;
    padding-bottom: 2rem;
    .previewComponent{
        padding-left: 2rem;
        padding-right: 2rem;
    }


label{
    color:#8E8E98;
    font-size:14px
}



.InputLabel{
    width: 74%;
    margin-bottom: 10px;
    font-weight: 400;
    margin-top:20px;
    color: #8E8E98;
}
.MuiFormControl-root{
    width: 29%;
}



.MuiFormControl-marginNormal{
    width:84%;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-top: 0px;
    .MuiInput-root{
        width: 100%;
        height: 41px;
    }
}

}

.req{
    color: red;
}
.savebutton {
    background-color: #00D95E;
    text-transform: capitalize;
    color: #fff;
    height: 33px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 2px;
    position: relative;
    margin-right: 6.5rem;}
    .savebutton:hover {
        background-color: #00D95E;
        text-transform: capitalize;
        color: #fff;
        height: 33px;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 2px;
        position: relative;
        margin-right: 6.5rem;}
.cancelbutton {
    border: 1px solid #E4E4E4;
    text-transform: capitalize;
    color: #8E8E98;
    height: 33px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 2px;
    margin: 2%;
    position: relative;
}
text{
    color:rgb(57, 57, 59);
    font-size:1.2rem;
    margin-top: 7px;
}
}
