.loginPage {
  min-height: 78vh;
  .mainDiv {
    width: 100%;
    height: 78vh;
  }
  .paddingLoginGrids {
    padding: 50px 20px 20px 20px;
  }
  .firstDiv {
    align-items: center;
    justify-content: center;
    // margin: 2rem;
    margin-bottom: 0.5rem !important;
  }
  .firstDivOne {
    text-align: left;
    padding: 0% 20%;
  }
  .firstH3 {
    font-family: "Comic Sans MS";
    font-size: 25px;
    font-weight: 300;
    text-align: center;
  }
  .firstH4 {
    margin-top: 0px;
    color: rgb(184, 184, 187);
    font-weight: 200;
    text-align: center;
    margin-bottom: 20px;
  }
  .secondH4 {
    // margin-top: 10px;
    color: #8e8e98;
    text-align: center;
    margin: 10px 10% 20px 10%;
  }
  // .secondDiv {
  //   display: flex !important;
  //   width: 100%;
  // }
  .ImageDiv {
    // width: 65%;
    // text-align: end;
    padding-top: 1rem;
    // margin-left: 10%;
  }
  .storeButtons {
    // margin-top: -4rem;
    // width: 50%;
    text-align: center;
    font-size: 20px;
    color: #8e8e98;
  }
  // display: flex;
  .storeButtonsOne {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 10px;
  }
  .googlePlay {
    width: 8rem;
    margin-right: 5px;
  }
  .appStore {
    width: 8rem;
    margin-left: 5px;
  }
  .clickHere {
    margin-top: 1rem;
  }
  .CardDiv {
    width: 50%;
  }
  .CardDiv2 {
    height: 100%;
    // border-radius: 13px!important;
  }
  .CardDivLogin {
    width: 80%;
    border-radius: 13px !important;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
      0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.8) !important;
    margin: 5% 10% 0 10%;
  }
  .CardDiv4 {
    margin: 2rem !important;
  }
  .CardDiv5 {
    text-align: center;
    font-size: 20px;
  }
  .textLeft {
    text-align: left !important;
  }
  form {
    padding: 1rem 0rem 1rem 0rem;
  }
  .btnDiv {
    padding: 2rem 0rem 1rem 0rem;
  }
  .linkDiv {
    text-align: end;
  }
  .inputBoxContent {
    border-bottom: 1px solid lightgrey !important;
  }
  // .mainCard {
  //     height: 100%;
  //     border-radius: 13px;
  //     box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  //   transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  // }
  input {
    color: #8e8e98;
  }
  h3 {
    font-weight: 400px;
  }
  .btn {
    background-image: linear-gradient(to right, #00d95e, #4277db);
    border: none !important;
    height: 3rem !important;
    color: white !important;
    text-transform: none !important;
  }
  .loginIMage {
    height: 17rem !important;
    width: 100%;
  }
  .MuiInputBase-input {
    padding: 6px -1px 8px 1px !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    color: #3e4664 !important;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (min-width: 320px) and (max-width: 480px) {
  .loginPage {
    .firstDivOne {
      padding: 0% 5%;
    }
    .firstH4 {
      font-size: 13px;
    }
    .firstDiv {
      margin-bottom: 1rem;
    }
    .firstH3 {
      font-size: 18px;
      font-weight: 400;
    }
    .secondDiv {
      display: block !important;
      width: 100%;
    }
    .ImageDiv {
      width: 90%;
      padding: 0% 5%;
    }
    .storeButtons {
      // margin-top: 1rem;
      // width: 80%;
      font-size: 13px;
      font-weight: 500;
      padding: 0% 10%;
      padding-bottom: 3rem;
    }

    .googlePlay {
      position: relative;
      width: 5rem;
      margin-right: 3px;
    }
    .appStore {
      width: 5rem;
      margin-left: 3px;
    }

    .CardDiv {
      width: 80%;
      padding: 0% 10%;
    }
    .CardDiv3 {
      width: 100%;
    }
    .linkDiv {
      font-size: 15px;
    }
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 959px) {
  .dBlockCss {
    display: block !important;
  }
}

@media only screen and (min-device-width: 0px) and (max-device-width: 599px) {
  .dBlockCss {
    display: block !important;
  }
  .loginPage {
    .CardDivLogin {
      width: 100%;
      margin: 0 !important;
    }
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-device-width: 481px) and (max-device-width: 767px) and (min-width: 481px) and (max-width: 767px) {
  .loginPage {
    .firstDivOne {
      padding: 0% 5%;
    }
    .firstH4 {
      font-size: 15px;
    }
    .firstDiv {
      margin-bottom: 1rem;
    }
    .firstH3 {
      font-size: 22px;
    }
    .secondDiv {
      display: block !important;
      width: 100%;
    }
    .ImageDiv {
      width: 80%;
      padding: 0% 10%;
    }
    .storeButtons {
      // margin-top: 1rem;
      // width: 80%;
      font-size: 16px;
      font-weight: 500;
      padding: 0% 10%;
      padding-bottom: 3rem;
    }

    .googlePlay {
      position: relative;
      width: 6rem;
      margin-right: 3px;
    }
    .appStore {
      width: 6rem;
      margin-left: 3px;
    }

    .CardDiv {
      width: 70%;
      padding: 0% 15%;
    }
    .CardDiv3 {
      width: 100%;
    }
    .linkDiv {
      font-size: 15px;
    }
  }
}
/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  .loginPage {
    .firstDivOne {
      padding: 0% 5%;
    }
    .firstH4 {
      font-size: small;
    }
    .firstDiv {
      margin-bottom: 0.5rem;
    }
    .firstH3 {
      font-size: 15px;
      font-weight: 300;
    }
    .secondDiv {
      display: block !important;
      width: 100%;
    }
    .ImageDiv {
      width: 90%;
      padding: 0% 5%;
    }
    .storeButtons {
      // margin-top: 1rem;
      // width: 80%;
      font-size: 13px;
      font-weight: 500;
      padding: 0% 10%;
      padding-bottom: 3rem;
    }

    .googlePlay {
      position: relative;
      width: 5rem;
      margin-right: 3px;
    }
    .appStore {
      width: 5rem;
      margin-left: 3px;
    }

    .CardDiv {
      width: 80%;
      padding: 0% 10%;
    }
    .CardDiv3 {
      width: 100%;
    }
    .linkDiv {
      font-size: 15px;
    }
    .loginIMage {
      height: 11rem !important;
    }
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  .loginPage {
    .firstDivOne {
      padding: 0% 5%;
    }
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* STYLES GO HERE */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* STYLES GO HERE */
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* STYLES GO HERE */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* STYLES GO HERE */
}

/* iPhone 5 (portrait &amp; landscape)----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) {
  /* STYLES GO HERE */
}

/* iPhone 5 (landscape)----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
  /* STYLES GO HERE */
}

/* iPhone 5 (portrait)----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
  /* STYLES GO HERE */
}
