.customerMainHeader{
    .Customerheader{
        width: 100%;
        background-color: #FFFFFF;
        // .MuiTypography-body1 {
        //       display: contents;
        //       }
        .MuiButton-root:hover{
            background-color: transparent !important;
        }        
    }
    .buttonDiv{
        display: flex;
        .buttonDivButtons{
            display: flex;
            position: absolute;
            right: 3%;
        }
        .buttonDivOne{
    display: flex;
        }
        .buttonDivTwo{
            .buttonDivTwoOne{            
                display: flex;
                width:243px;
                .buttonDivTwoOneONe{
                    border-left: 1px solid lightgrey;
                    margin-right: 26px;
                    height: 18px;
                    margin-top: 7px;
                                }
            }
        }
        .buttonDivThree{
            display: flex;
            justify-content: space-between;
        }
        .buttonDivFour{
           display: flex; 
                }
       
        
    }
    .bar {
        background-color: transparent!important;
        // color: black!important;
        box-shadow: none!important;
        // padding-top: 15px;
        .title{
            text-align: left;
            width: 31%;
            padding-left: 6rem;
            .Tabbutton {
                border: none;
                color: #757575;
                text-transform: capitalize;
                cursor: pointer;
            }
        }
        .titleOne{
            text-align: left;
            width: 50%;
            padding-left: 6rem;
            .Tabbutton {
                border: none;
                color: #757575;
                text-transform: capitalize;
                cursor: pointer;
            }
        }
        .OptionDiv{
            width: 33%;
            text-align: end;
            display: flex;
            padding-right: 2.7rem;
            justify-content: flex-end;
            .textBox{
                background-color: #EFF2FF;
                border-radius: 5px;
                padding-top: 3px;
            }
           
            .option{
                padding: 20px;
                text-decoration: none;
                color: #D4D4D5;
                cursor: pointer;
            }
            .option:hover{
                // padding: 20px;
                text-decoration: none;
                color: #4D66F0 !important;
            }
        }
        .searchDiv {
            text-align: left;
            display: flex;
            width: 27%;
            // margin-left: 30%;
            background-color: #EFF2FF;
            border-radius: 8px;
            margin-right: 25px;
        }
           
            .dropdown-menu {
                position: absolute !important;
                will-change: transform !important;
                top: 0px !important;
                left: 0px !important;
                transform: translate3d(-44px, 34px, 0px) !important;
                background-color: #3E4664;
                .dropdown-item{
                    color: #fff;
                    font-weight: 400
                }
                .dropdown-item:hover {
                    background-color: #757575;
                }
            }
            .dropdown-toggle::after {
                display: none;
            }
            .ButtonDiv {
                // position: absolute;
                // right: 50px; 
                // top: 14px;
                padding-left: 20px;
                .adduserbutton {
                    text-transform: capitalize;
                    color: #ffff !important;
                    padding-left: 15px;
                    padding-right: 15px;
                    border-radius: 5px;
                    height: 34px;
                    padding-top: 4px;
                    background-color: #00D95E !important;
                    color: #fff !important;
                    .plusiconButton{
                        padding-right: 5px;
                    }
                }
                .adduserbutton:hover {
                    text-transform: capitalize;
                    color: #ffff;
                    padding-left: 15px;
                    padding-right: 15px;
                    border-radius: 5px;
                    height: 34px;
                    padding-top: 4px;
                    background-color: #00D95E !important;
                    .plusiconButton{
                        padding-right: 5px;
                    }
                }
            }
           
        }
        .imagediv {
            padding-left: 15px;
            padding-top: 8px;
        }
        .gridDiv{
            padding-left: 15px;
            padding-top: 8px;
        }
       .MuiInputBase-input{
                padding: 6px 21px 8px 11px !important;
                font-size: 15px !important;
                // color: #3E4664 !important;
                font-family: "Lato" !important;
            }
            .MuiSelect-select:focus {
                background-color: transparent;
                color: #00D95E !important
            }
            // .MuiSelect-select{
            //     color: #00D95E !important
            // }
            .cardtypeicon{
                cursor: pointer;
            }
            .gridtypeicon {
                cursor: pointer;
            }
            // .MuiButton-root{
            //     color: #fff !important;
            // }
            .filterDrop {
                background-color:transparent;
                border: 1px solid #E4E4E4;
                color: #3E4664;
                height: 36px;
                .filtericon{
                    padding-left: 15px;
                    padding-bottom: 3px 
                }
            }
            .filterDrop:focus{
                background-color: transparent;
                border: 1px solid #00D95E;
                color: #00D95E;
                box-shadow: none !important;
            }
            .timeDrop {
                background-color: #EFF2FF;
                border: none;
                color: #3E4664;
                height: 36px;
                width: fit-content;
                text-transform: capitalize;
                border-radius: 5px;
                margin-right: 20px;
                text-transform: capitalize;
                .calendar{
                    margin-left: 10px;
                    width: 16px;
                }
                .menuItemsList{
                    top: 280px !important;
                }
        
            .timeDrop:focus{
                background-color: #EFF2FF;
                border: 1px solid #00D95E;
                color: #00D95E;
                box-shadow: none !important;
            }
        }
        
        .MuiTypography-body1 {
            //   display: contents;
              }
              .MuiBadge-root {
                float: right;   
                         
            }
            .menuItemsList{
                top: 280px !important;
            }
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (min-width: 320px) and (max-width: 480px) {
        .customerMainHeader{
                   .buttonDiv{
                       .buttonDivOne{
                           display: block;
                       }
                
                display: block;
                .buttonDivButtons{
                    display: block;
                    position: relative;
                    margin-left: 20%;
                    right: 0%;
                        }
                      .buttonDivTwo{
                    .buttonDivTwoOne{
                       padding : 0rem 1rem 2rem 20%;
                       .buttonDivTwoOneONe{
                        display: none;
                                    } 
                    }
                }
                        
            }
            
            
            
            
            
            .bar {            
                .titleOne{
                    text-align: left;
                    width:67%;
                    padding:2rem;
                    padding-left: 20%;
                    
                    }
                .OptionDiv{
                    width: 75%;
                padding-left: 21%;
                 padding-right: 0rem !important;
                    justify-content: initial;
                            .option{
                                padding: 20px 18px  ;
                        }
                    }
                .searchDiv {
                    width: 70%;
                    margin-left: 23%;  
                           
                }
               .ButtonDiv {
                         padding-left: 0px;
                          }
                   
                }
                  
                .MuiToolbar-root {
                    display: block;
                   
                }  
                    
                 
            
            }
            
       
       
        .customerMainHeader{
        .Customerheader{    
            .bar { 
                         
                .searchDiv {
                     width: 70%;
                    margin-left: 23%;               
                }            
            }   
            .MuiToolbar-root {
                display: block;
               
            }         
        }
    }}
    /* Smartphones (landscape) ----------- */
    @media only screen and (min-device-width: 481px) and (max-device-width: 800px) and (min-width: 481px) and (max-width: 800px) {
        .customerMainHeader{
            .buttonDiv{
         
         display: block;
         .buttonDivButtons{
             display: flex                      ;
             position: relative;
             margin-left: 15%;
             right: 0%;
                 }
               .buttonDivTwo{
             .buttonDivTwoOne{
                 width: 190px;
                padding: 1rem 0rem 1rem 0rem;
                position: absolute;
                right: 1%; 
                .buttonDivTwoOneONe{
                    margin-right: 10px;
                                } 
             }
         }
                 
     }
     
     
     
    .buttonDiv{
       .buttonDivThree{
            display: flex;
            justify-content: space-between;
            padding: 0rem 4rem 0rem 0rem;
        }                           
        .buttonDivFour{
           display: flex; 
           position: absolute;
           right: 2%;
                }
       
        
    }
     
     .bar {            
         .titleOne{
             text-align: left;
             width:67%;
          min-width: 48%;
             padding-left: 15%;
             margin-top: .5rem;
             margin-bottom: 1.5rem;
             
             }
         .OptionDiv{
             width: 75%;
         padding-left: 21%;
          padding-right: 0rem !important;
             justify-content: initial;
                     .option{
                        padding: 20px 18px  ;
                 }
             }
         .searchDiv {
             width: 70%;
             margin-left: 23%;         
         }
        .ButtonDiv {
                  padding-left: 0px;
                   }
            
         }
           
         .MuiToolbar-root {
             display: block;
            
         }  
             
          
     
     }
     
    .customerMainHeader{
    .Customerheader{    
     .bar { 
                  
         .searchDiv {
              width: 70%;
             margin-left: 23%;               
         }            
     }   
     .MuiToolbar-root {
         display: block;
        
     }         
    }
    }
       }
    /* Smartphones (portrait) ----------- */
    @media only screen and (max-width: 500px) {
        .customerMainHeader{
            .buttonDiv{
                .buttonDivOne{
                    display: block;
                }
                .buttonDivFour {
                    display: flex;
                    justify-content: flex-end;
                }
         
         display: block;
         .buttonDivButtons{
             display: block;
             position: relative;
             margin-left: 29% !important;
            margin-top: -7% !important;
             right: 0%;
                 }
               .buttonDivTwo{
             .buttonDivTwoOne{
                padding : 0rem 3rem 2rem 8rem !important;
             }
         }
                 
     }
     
     
     
     
     
     .bar {            
         .titleOne{
             text-align: left;
             width:67%;
             padding:2rem;
             padding-left: 20%;
             
             }
         .OptionDiv{
             width: 75%;
         padding-left: 21%;
          padding-right: 0rem !important;
             justify-content: initial;
                     .option{
                        padding: 20px 18px  ;
                 }
             }
         .searchDiv {
             width: 70%;
             margin-left: 23%;         
         }
        .ButtonDiv {
                  padding-left: 0px;
                   }
            
         }
           
         .MuiToolbar-root {
             display: block;
            
         }  
             
          
     
     }
     
    .customerMainHeader{
    .Customerheader{    
     .bar { 
                  
         .searchDiv {
              width: 70%;
             margin-left: 23%;               
         }            
     }   
     .MuiToolbar-root {
         display: block;
        
     }         
    }
    }
    }
    /* iPads (portrait and landscape) ----------- */
    @media only screen and (min-device-width: 800px) and (max-device-width: 1024px) and (min-width: 800px) and (max-width: 1024px) {
        .customerMainHeader{
            display: block;
            .buttonDiv{
                .buttonDivOne{
                    display: flex;
                }
         
       
         .buttonDivButtons{
            right: 2%;
                 }
               .buttonDivTwo{
             .buttonDivTwoOne{
                padding : 4px;
                padding-top: 5px;
             }
         }
                 
     }
     
     
     
     
     
     .bar {            
         .titleOne{
             text-align: left;
             width:26%;
             padding:0rem;
             padding-left: 12%;
             
             }
         .OptionDiv{
             width: 50%;
         padding-left: 3%;
          padding-right: 0rem !important;
             justify-content: initial;
                     .option{
                        padding: 20px 18px  ;
                 }
             }
         .searchDiv {
             width: 70%;
             margin-left: 5% !important;         
         }
        .ButtonDiv {
                  padding-left: 0px;
                   }       
         }    
         .MuiToolbar-root {
             display: block;        
         }  
             
          
     
     }
     
    .customerMainHeader{
    .Customerheader{    
     .bar { 
                  
         .searchDiv {
              width: 70%;
             margin-left: 23%;               
         }            
     }   
     .MuiToolbar-root {
         display: flex;
        
     }         
    }
    }
    }
    /* iPads (landscape) ----------- */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    }
    /* iPads (portrait) ----------- */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      /* STYLES GO HERE */
    }
    /* Desktops and laptops ----------- */
    @media only screen and (min-width: 1224px) {
      /* STYLES GO HERE */
      .customerMainHeader{
        display: block;
        .buttonDiv{
            .buttonDivOne{
                display: flex;
                width: 37rem;
            }
     
     .buttonDivButtons{
        right: 3%;
             }
           .buttonDivTwo{
         .buttonDivTwoOne{
            padding : 4px;
            padding-top: 5px;
         }
     }
             
    }
    .bar {            
     .titleOne{
         text-align: left;
         width:100%;
         padding:0rem;
         padding-left: 16%;
         
         }
     .OptionDiv{
         width: 33%;
     padding-left: 3%;
      padding-right: 0rem !important;
         justify-content: initial;
                 .option{
                    padding: 20px 18px  ;
             }
         }
     .searchDiv {
         width: 27%;
         margin-left: 5% !important;         
     }
    .ButtonDiv {
              padding-left: 0px;
               }       
     }    
     .MuiToolbar-root {
         display: block;        
     }  
         
      
    }
    .customerMainHeader{
    .Customerheader{    
    .bar { 
              
     .searchDiv {
          width: 27%;
         margin-left: 23%;               
     }            
    }   
    .MuiToolbar-root {
     display: flex;
    }         
    }
    }
    }
    /* Large screens ----------- */
    @media only screen and (min-width: 1824px) {
        .customerMainHeader{
            display: block;
            .buttonDiv{
                .buttonDivOne{
                    display: flex;
                    width: 37rem;
                }
         
        
         .buttonDivButtons{
            right: 3%;
                 }
               .buttonDivTwo{
             .buttonDivTwoOne{
                padding : 4px;
                padding-top: 5px;
             }
         }
                 
        }
        
        
        
        
        
        .bar {            
         .titleOne{
             text-align: left;
             width:100%;
             padding:0rem;
             padding-left: 12%;
             
             }
         .OptionDiv{
             width: 50%;
         padding-left: 3%;
          padding-right: 0rem !important;
             justify-content: initial;
                     .option{
                        padding: 20px 18px ;
                 }
             }
         .searchDiv {
             width: 70%;
             margin-left: 5% !important;         
         }
        .ButtonDiv {
                  padding-left: 0px;
                   }       
         }    
         .MuiToolbar-root {
             display: block;        
         }  
             
          
        
        }
        
        
        
        .customerMainHeader{
        .Customerheader{    
        .bar { 
                  
         .searchDiv {
              width: 70%;
             margin-left: 23%;    
             margin-right: 25px;           
         }            
        }   
        .MuiToolbar-root {
         display: flex;
        
        }         
        }
        }
    }
    /* iPhone 5 (portrait &amp; landscape)----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) {
      /* STYLES GO HERE */
    }
    /* iPhone 5 (landscape)----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: landscape) {
      /* STYLES GO HERE */
    }
    /* iPhone 5 (portrait)----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
      /* STYLES GO HERE */
    }


    @media only screen and (min-device-width: 0px) and (max-device-width: 959px) {
    .customerMainHeader{
        
        .bar {
            .titleOne{
                padding: 1rem !important;
                padding-left: 6rem !important;
            }
        }
    }
}
    