.loan-application-form {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 20px;
}

.loan-application-form input {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;
  box-sizing: border-box;
}

.inputDisabledLA input[disabled] {
  background-color: white;
  color: black;
  border: 0.5px solid #d1d1d1;
}

label {
  display: inline-block;
  width: 200px;
}

/* button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
} */

legend {
  background: #d1d1d1;
  padding: 5px 20px;
}

.declaration-form {
  font-family: Arial, sans-serif;
  width: 90%;
  margin: 20px;
  padding: 20px;
  border: 1px solid #fff;
  border-radius: 5px;
}

.declaration-form h2 {
  text-align: left;
  margin: 0 !important;
  padding: 0 !important;
}

.declaration-form p {
  text-align: justify;
  line-height: 1.6;
}

.input-line {
  display: inline-block;
  width: 180px;
  border-bottom: 1px dotted #000;
  margin-left: 5px;
  margin-right: 5px;
}

.agreement {
  margin-top: 10px;
}

.agreement label {
  margin-right: 20px;
  font-size: 16px;
}

.agreement input[type="checkbox"] {
  margin-left: 10px;
}

.form-section {
  margin-bottom: 20px;
}

.photo-box {
  display: inline-block;
  width: 95%;
  vertical-align: top;
  padding: 10px;
  border: 1px solid #000;
  margin-right: 5%;
  margin-bottom: 20px;
  min-height: 150px;
  border-collapse: collapse;
}

.photo-placeholder {
  border: 1px solid #000;
  width: 150px;
  height: 200px;
  margin: 0px auto;
  text-align: center;
  margin-bottom: 10px;
}

.input-group {
  margin-bottom: 10px;
}

.input-group label {
  display: inline-block;
  width: 150px;
  text-align: left;
}

.underline-input {
  border: none;
  border-bottom: 1px solid #000;
  width: calc(100% - 160px);
  padding: 5px;
  outline: none;
}

.acknowledgement-section {
  border-top: 2px solid #000;
  padding-top: 20px;
}

.acknowledgement-section h3 {
  margin-bottom: 20px;
}

@media print {
  .page-break {
    page-break-before: always;
  }

  .loan-application-form {
    page-break-inside: avoid;
  }

  .section {
    page-break-inside: avoid;
  }
}
